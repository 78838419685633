import React, {useContext, useEffect, useState} from "react";

import Searchbar from "../../../customUI/Searchbar/Searchbar";
import {ToogleVisible} from "../../../customUI/Searchbar/functions"
import AdminService from "../../../../services/AdminService";
import CustomTable, {IRowElement, ITable} from "../../../customUI/CustomTable/CustomTable";
import {Link} from "react-router-dom";
import "./Customers.css"
import CustomNavigateHeader from "../../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import {Context} from "../../../../index";
import {AdminPopupApply} from "../../../customUI/AdminPopupApply/Popup_Apply";
import Navbar from "../Navbar/Navbar";
import {SpaRoutes} from "../../../../Routes/spaRoutes";

const placeholder = 'Найти косметолога'

const Customers = () => {

    const {store} = useContext(Context);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    function CreateShortName(Name: string) {
        for (const char of Name) {
            if (char !== " ") return `${char}.`;
        }
    }

    const [suppliersTable, setSuppliersTable] = useState<ITable>({
        tableId: "CustomersTable",
        header: [],
        rows: []
    });
    const [showPopup, setShowPopup] = useState(false)
    const [popupElement, setPopupElement] = useState<any>();

    const openPopup = (id: string, name: string, imageUrl: string, type: string) => {
        let textOnButton = ""
        let thisHandler = () => {
        }
        if (type === "activate") {
            textOnButton = "Активировать"
            thisHandler = () => {
                AdminService.postActivateUser(id, true)
                    .finally(() => {
                        setShowPopup(false)
                        getTable()
                    })
            }
        } else if (type === "deactivate") {
            textOnButton = "Деактивировать"
            thisHandler = () => {
                AdminService.postActivateUser(id, false)
                    .finally(() => {
                        setShowPopup(false)
                        getTable()
                    })
            }
        }
        setPopupElement(AdminPopupApply({
            title: name,
            textOnButton: textOnButton,
            handler: thisHandler,
            imageUrl: imageUrl ? (store.imgServer + imageUrl) : ("/Pictures/supplierNoImage.svg"),
            onClose: () => {
                setShowPopup(false)
            }
        }))
        setShowPopup(true);
    }

    const getTable = () => {
        AdminService.getBuyers()
            .then((response) => {
                let list: IRowElement[] = [];
                for (const customer of response.data) {
                    list.push(
                        {
                            content: {
                                fullName:
                                    <div
                                        data-search-word={`${customer.lastName} ${customer.firstName} ${customer.vatherName}`}>
                                        {`${!customer.firstName ? 'Мед. учреждение:' : ''} ${customer.lastName ? customer.lastName : ''} ${customer.firstName ? CreateShortName(customer.firstName) : ''} ${customer.vatherName ? CreateShortName(customer.vatherName) : ''}`}
                                    </div>,
                                displayedName: <div
                                    data-search-word={`${customer.displayedName}`}>{customer.displayedName}</div>,
                                region: <div data-search-word={`${customer.region}`}>{customer.region}</div>,
                                city: <div data-search-word={customer.city}>{customer.city}</div>,
                                telephone: <div>{customer.phone}</div>,
                                email: <div>{customer.email}</div>,
                                statusEmail: <div>{customer.emailConfirmed ? `Активна` : `Не активна`}</div>,
                                statusActivation:
                                    customer.isActive ?
                                        <div className={"hoverChild"} style={{
                                            top: "-4px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }} onClick={() => {
                                            openPopup(customer.id, customer.displayedName, customer.profilePictureURL, "deactivate")
                                        }}>
                                            <div className={"bdproTab_disField"}>Активна</div>
                                            <div>
                                                <img src="/Pictures/VectorRedCross.svg" alt="Деактивировать"/>
                                            </div>
                                        </div>
                                        : <div className={"hoverChild"} style={{
                                            top: "-4px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }} onClick={() => {
                                            openPopup(customer.id, customer.displayedName, customer.profilePictureURL, "activate")
                                        }}>
                                            <div className={"bdproTab_disField"}>Не активна</div>
                                            <div>
                                                <img src="/Pictures/VectorGreenPlus.svg" alt="Активировать"/>
                                            </div>
                                        </div>,
                                registration: <div>{customer.registered ? customer.registered.split("T")[0] : ""}</div>,
                                linkCustomer:
                                    <div style={{}}>
                                        <Link to={SpaRoutes.ADMIN_CHAT + `/:${customer.id}/:${1}/:${0}`}>
                                            <img alt={"to customer"} className={"table-iconUser"}
                                                 src={"/Pictures/message-square.svg"}/>
                                        </Link>
                                        <Link to={SpaRoutes.ADMIN_BUYER + `/${customer.id}`}>
                                            <img alt={"to customer"} className={"table-iconUser"}
                                                 src={"/Pictures/NoteCustomer.svg"}/>
                                        </Link>
                                    </div>
                            },
                            filter: [],
                            visible: [true, true, true]
                        }
                    )
                }
                let mytable: ITable = {
                    tableId: "CustomersTable",
                    header: ["ФИО", "Название на платформе", "Регион", "Город", "Номер телефона", "Электронная почта", "Статус почты", "Статус активации", "Дата регистрации", ""],
                    //rows:[]
                    rows: list
                }
                setSuppliersTable(mytable);
            })
            .catch(() => {
                store.ErrorON()
            })
            .finally(() => {
                store.DataLoadingOFF()
            })
    }

    useEffect(() => {
        getTable()
    }, [])

    const changeHandler = (text: string) => {
        setSuppliersTable((prevState) => {
                const newState = {...prevState}
                newState.rows = ToogleVisible(text, ["fullName", "displayedName", "region", "city"], ["", "", "", ""], newState.rows)
                return newState;
            }
        )

    }

    return (
        <>{(!store.isError && !store.isDataLoading) &&
            <div className="admin-customers">

                {/*<div className={"admin-navbar-main"}>*/}
                {/*    <Navbar/>*/}
                {/*</div>*/}

                <div className={"admin-customers-main"}>
                    <CustomNavigateHeader title={"Косметологи"} withTrash={false}/>
                    <Searchbar
                        changeHandler={changeHandler}
                        initialText={placeholder}
                    />
                    <CustomTable {...suppliersTable}/>
                    {showPopup && popupElement}
                </div>
            </div>
        }</>
    )
}
export default Customers;

