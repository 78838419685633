import React, {useContext, useEffect, useRef, useState} from 'react';
import styles from "./Clients.module.scss";
import CustomNavigateHeader from "../../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import Searchbar from "../../../customUI/Searchbar/Searchbar";
import CustomAntTable from "../../../customUI/CustomAntTable/CustomAntTable";
import CustomDropdown from "../../../customUI/CustomDropdown/CustomDropdown";
import CabinetSellerService from "../../../../services/CabinetSellerService";
import {Context} from "../../../../index";
import {SpaRoutes} from "../../../../Routes/spaRoutes";
import {Link} from "react-router-dom";
import HomeService from "../../../../services/HomeService";
import {Button, Dropdown, Menu, MenuProps} from "antd";

const Clients = () => {

    const ChangeStatus: React.FC<{ status: number; record: any }> = ({ status, record }) => {
        const statuses = [
            { name: "Стандартный", key: 0 },
            { name: "Серебряный", key: 1 },
            { name: "Золотой", key: 2 },
        ];

        const [currentStatus, setCurrentStatus] = useState<string>(statuses[status].name);
        const [loading, setLoading] = useState<boolean>(loadingTable);

        const handleMenuClick = (e: any) => {
            setLoading(true); // Включаем состояние загрузки

            let data = {
                Info1: record.sellerClientId,
                Number1: e?.key,
            };

            CabinetSellerService.changeClientStatus(data)
                .then(() => setCurrentStatus(statuses[e?.key].name))
                .catch(() => store.showDialog("Что-то пошло не так"))
                .finally(() => setLoading(false)); // Отключаем состояние загрузки
        };

        const items: MenuProps['items'] = statuses.map(s => ({
            label: s.name,
            key: s.key,
        }));

        return (
            <Dropdown menu={{ items, onClick: handleMenuClick }} trigger={['click']} disabled={loading}>
                <Button
                    style={{
                        width: "120px",
                    }}
                    loading={loading}
                >
                    {!loading && (currentStatus || "Нет")} <span>▼</span>
                </Button>
            </Dropdown>
        );
    };


    const columns = [
        {
            title: "Имя",
            dataIndex: "name",
            render: (_, record: any) => (`${record.lastName} ${record.firstName} ${record.fatherName}`),
            width: 200, // Установлено фиксированное значение (например, 200px)
        },
        {
            title: "Статус",
            dataIndex: "status",
            render: (status: number, record) => {
                return <ChangeStatus status={status} record={record} />
            },
            width: 100,
        },
        {
            title: "Регион",
            dataIndex: "region",
            width: 150,
        },
        {
            title: "Город",
            dataIndex: "city",
            width: 150,
        },
        {
            title: "Почта",
            dataIndex: "email",
            width: 200,
        },
        {
            title: "Телефон",
            dataIndex: "phone",
            width: 150,
        },
        {
            title: <span style={{ whiteSpace: 'nowrap' }}>Первая покупка</span>,
            dataIndex: "firstDate",
            render: (firstDate) => (firstDate.split("T")[0]),
            width: 150,
        },
        {
            title: "",
            dataIndex: "action",
            render: (_, record: any) => (
                <Link to={SpaRoutes.SUPPLIER_CHAT + `/:${record.clientId}/:${1}/:${0}`}>
                    <img alt={"to customer"} className={"table-iconUser"} src={"/Pictures/message-square.svg"} />
                </Link>
            ),
            width: 60,
        },
    ];

    const {store} = useContext(Context);
    const [loadingTable, setLoadingTable] = useState<boolean>(false)

    const [searchText, setSearchText] = useState("");
    const [statusName, setStatusName] = useState("Все");
    const [selectedStatus, setSelectedStatus] = useState<string>("0");

    const arrStatuses = [
        {name: "Все", key: "0"},
        { name: "Стандартный", key: "1" },
        { name: "Серебряный", key: "2" },
        { name: "Золотой", key: "3" },
    ];

    const [regions, setRegions] = useState<[any]>([]);
    const [selectedRegionName, setSelectedRegionName] = useState<string>("Все");
    const [selectedRegion, setSelectedRegion] = useState<string>("");

    const arrRegions = [{name: "Все", key: "Все"}]
    regions.map((region: any) => (arrRegions.push({name: region, key: region})))

    const [cities, setCities] = useState<[any]>([]);
    const [selectedCityName, setSelectedCityName] = useState<string>("Все");
    const [selectedCity, setSelectedCity] = useState<string>("");

    const arrCities = [{name: "Все", key: "Все"}]
    cities.map((city: any) => (arrCities.push({name: city, key: city})))

    const [warehouses, setWarehouses] = useState<[any]>([]);
    const [selectedWarehouseName, setSelectedWarehouseName] = useState<string>("Все");
    const [selectedWarehouseId, setSelectedWarehouseId] = useState<string>("");

    const arrWarehouses = [{name: "Все", key: "Все"}]
    warehouses.map((warehouse: any) => (
        arrWarehouses.push(
            {
                name: warehouse.isVirtual ? `Виртуальный для ${warehouse.virtualRegion} (${warehouse.city}, ${warehouse.street} ${warehouse.number})`
                    : `${warehouse.city}, ${warehouse.street} ${warehouse.number}`,
                key: warehouse.dropAdressId
            }
        )
    ))

    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [totalFilterCount, setTotalFilterCount] = useState(0);

    const [clients, setClients] = useState<any>([]);


    useEffect(() => {
        store.DataLoadingON()
        CabinetSellerService.getClientsRegionsAndWarehouses()
            .then((r) => {
                setRegions(r.data.regions)
                setCities(r.data.cities)
                setWarehouses(r.data.warehouses)
            })
            .catch(() => store.showDialog("Что-то пошло не так"))
            .finally(() => store.DataLoadingOFF())
    }, []);

    const getClients = (searchText: string, status: string, region: string, city: string, dropAddressId: string, page: number) => {
        setLoadingTable(true)
        let data = {
            Info1: searchText,
            Info2: region,
            Info3: city,
            Info4: dropAddressId,
            Number1: status,
            Number2: page,
        };
        CabinetSellerService.getClients(data)
            .then(r => {
                setClients(r.data.clients);
                setTotalCount(r.data.totalCount);
                setTotalFilterCount(r.data.totalFilterCount)
            })
            .catch(() => store.showDialog("Что-то пошло не так"))
            .finally(() => setLoadingTable(false));
    };

    const selectStatus = (key: string) => {
        setSelectedStatus(key);
        setPage(1);
    };

    const selectRegion = (key: string) => {
        if(key == "Все") {
            setSelectedRegionName(key);
            setSelectedRegion("");
        }
        else setSelectedRegion(key);
        setPage(1);
    };

    const selectCity = (key: string) => {
        if(key == "Все"){
            setSelectedCityName(key);
            setSelectedCity("")
        }
        else setSelectedCity(key);
        setPage(1);
    };

    const selectWarehouse = (key: string) => {
        if(key == "Все"){
            setSelectedWarehouseName(key);
            setSelectedWarehouseId("");
        }
        else setSelectedWarehouseName(key);
        setPage(1);
    };

    const handleTableChange = (pagination: any) => {
        setPage(pagination.current);
    };

    useEffect(() => {
        getClients(searchText, selectedStatus, selectedRegion, selectedCity, selectedWarehouseId, page);
    }, [selectedStatus, selectedRegion, selectedCity, selectedWarehouseId, page]);

    const isInitialMount = useRef(true);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            return; // Пропускаем выполнение эффекта на монтировании
        }
        const handler = setTimeout(() => {
            getClients(searchText, selectedStatus, selectedRegion, selectedCity, selectedWarehouseId, page);
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [searchText]);

    return (
        <div className={styles.container}>
            <CustomNavigateHeader title={"Клиенты"} withTrash={false}/>
            <Searchbar initialText={"Поиск"} changeHandler={(text) => setSearchText(text)}/>
            <div className={styles.filter}>
                <div className={styles.filterStatusContainer}>
                    Статус
                    <CustomDropdown
                        dropdownSize={"medium"}
                        options={arrStatuses}
                        value={statusName}
                        valueSetter={setStatusName}
                        onChangeHandler={selectStatus}
                    />
                </div>

                <div className={styles.filterRegionContainer}>
                    Регион
                    <CustomDropdown
                        dropdownSize={"medium"}
                        options={arrRegions}
                        value={selectedRegionName}
                        valueSetter={setSelectedRegionName}
                        onChangeHandler={selectRegion}
                    />
                </div>

                <div className={styles.filterCityContainer}>
                    Город
                    <CustomDropdown
                        dropdownSize={"medium"}
                        options={arrCities}
                        value={selectedCityName}
                        valueSetter={setSelectedCityName}
                        onChangeHandler={selectCity}
                    />
                </div>

                <div className={styles.filterWarehouseContainer}>
                    Склад
                    <CustomDropdown
                        dropdownSize={"medium"}
                        options={arrWarehouses}
                        value={selectedWarehouseName}
                        valueSetter={setSelectedWarehouseName}
                        onChangeHandler={selectWarehouse}
                    />
                </div>
            </div>

            <CustomAntTable
                data={clients.map((client: any) => ({ ...client, key: client.sellerClientId }))}
                columns={columns}
                pagination={{
                    current: page,
                    pageSize: 50,
                    total: totalFilterCount,
                    showSizeChanger: false
                }}
                scroll={{x: "max-content", y: "calc(100dvh - 500px)"}}
                onChange={handleTableChange}
                footer={() => <div>Всего
                    клиетов: {totalCount} {totalFilterCount != totalCount && `. Отфильтрованных клиентов ${totalFilterCount}`}</div>}
                loading={loadingTable}
            />

        </div>
    );
};

export default Clients;
