import {Link, useNavigate} from "react-router-dom"
import '../../../../../index.css'
import './UserMenuAuth.css'
import React, {useContext, useEffect, useState} from "react";
import '../../UserLayout.css'
import {strReplace} from "../../../../../functions/functions";
import {CustomIMG} from "../../../../customUI/CustomIMG/CustomIMG";
import AuthenticateService from "../../../../../services/AuthenticateService";
import {SpaRoutes} from "../../../../../Routes/spaRoutes";
import RegionSelector from "../../../../customUI/RegionSelector/RegionSelector";
import HomeService from "../../../../../services/HomeService";
import {IRegion} from "../../../../../models/models";
import {Context} from "../../../../../index";

interface IUserInfo {
    url?:string;
    name?:string;
    regionName?:string;
}

function UserMenuAuth() {
    const [userInfo, setUserInfo] = useState<IUserInfo>({url:undefined,name:undefined})
    const [regions, setRegions] = useState<IRegion[]>([])
    const {store} = useContext(Context)
    const [searchword, setSearchword] = useState<string>("")
    const navigate = useNavigate();

    const updateSearchword = (e:any)=>{
        const {name,value} = e.target;
        setSearchword(value);
    }


    const handleEnterKeyDown = (event:any) => {
        if (event.key === 'Enter') {
            // 👇 Get input value
            if (searchword.length>0){
                navigate(SpaRoutes.CATALOG_INDEX + "/:" + searchword);
            }
        }
    };
    useEffect(()=>{
        AuthenticateService.userInfo().then(data => {
                setUserInfo(data.data);
            })
        HomeService.GetRegions()
            .then((resp) => {
                // устанавливаем регионы
                setRegions(resp.data.regions);
            });
    },[store.regionId])
    return(
        <div id="menu-container">
            {/*<Link id={"menu-profile-div"} to={SpaRoutes.PROFILE}>*/}
            {/*    <div id="menu-profile">*/}
            {/*        <div id={"img-profile-container"}>*/}
            {/*            {userInfo.url == undefined?*/}
            {/*                <div id={"default-profile"}></div>:*/}
            {/*                <CustomIMG id="img-profile-menu" src={strReplace(userInfo.url)}/>*/}
            {/*            }*/}
            {/*        </div>*/}
            {/*        {userInfo?.name?<span>{userInfo.name}</span>:<span>User</span>}*/}
            {/*    </div>*/}
            {/*</Link>*/}
            <Link to={SpaRoutes.CATALOG_INDEX} onClick={() => store.FiltersSave("-1", "", "-1", "", [])}
                  className={"menu-a"}>
                {/*<img id={"menu-img"} src={"/Pictures/Значок.svg"}/>*/}
                <img className={"lupa-img"} onClick={() => navigate(SpaRoutes.CATALOG_INDEX + "/:" + searchword)}
                     src={"/Pictures/find.svg"}/>
                <p id={"menu-p"}>КАТАЛОГ</p>
            </Link>
            <div id="menu-location-Auth">
                {/*<img id="img-menu-location" src="/Pictures/Локация.svg"/>*/}
                <RegionSelector regions={regions} name={userInfo?.regionName ? userInfo.regionName : "Пермский край"}/>
            </div>
            <div className={"find-div"}>

                {/*<input placeholder={"Искать"} onClick={()=>navigate(SpaRoutes.CATALOG_INDEX + "/:" + searchword)} value={searchword} onKeyDown={(event)=>{handleEnterKeyDown(event)}} type={"text"} onChange={(event)=>{updateSearchword(event)}} className={"find-input"} />
                {searchword&&
                <img className={"SearchbarLogoUserMenu"} alt={""} src={'/Pictures/Close.svg'} onClick={(event) => setSearchword("")} />}*/}
            </div>
        </div>
    )
}

export default UserMenuAuth;