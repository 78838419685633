import {Swiper, SwiperSlide} from "swiper/react";

// Import CustomSwiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

import "./CustomSwiper.css";

// import required modules
import {FreeMode, Pagination} from "swiper";
import ProductCard from "../ProductCard/ProductCard";
import {Ikategori} from "../../../models/models";
import {useNavigate} from "react-router-dom";
import {SpaRoutes} from "../../../Routes/spaRoutes";
import {useContext} from "react";
import {Context} from "../../../index";

export default function CustomSwiper({data, title, toSub, id, isAuth, isMainPage}: Ikategori) {
    let ListCards: any = [];
    const {store} = useContext(Context)
    const navigate = useNavigate()
    if (data)
        if (data.length != 0)
            ListCards = data.map((card, index) => {
                if (isMainPage && index <= 5) {
                    return (
                        <SwiperSlide style={{width: "min-content"}} key={index + card.productId}>
                            <ProductCard
                                isAuth={isAuth ? isAuth : false}
                                key={index + card.productId}
                                item={card}
                            />
                        </SwiperSlide>
                    )
                }
            })

    const goCategory = () => {
        let filter = store.filterInfo
        filter.topId = `${id}`
        filter.topCatName = title
        filter.subId = "-1"
        filter.subCatName = "Подкатегория"
        if (isMainPage)
            filter.selectedIds = []
        store.FiltersSave(filter.topId, filter.topCatName, filter.subId, filter.subCatName, filter.selectedIds)
        navigate(SpaRoutes.CATEGORY + "/" + id + '/:')
    }

    const goSubCategory = () => {
        let filter = store.filterInfo
        filter.subId = `${id}`
        filter.subCatName = title
        if (isMainPage)
            filter.selectedIds = []
        store.FiltersSave(filter.topId, filter.topCatName, filter.subId, filter.subCatName, filter.selectedIds)
        navigate(SpaRoutes.SUB_CATEGORY + '/' + id + '/:')
    }

    return (<>
            {data && data.length != 0 &&
                <>
                    <div className={"categories-div"} onClick={() => {
                        toSub ?
                            goSubCategory()
                            : goCategory()
                    }}>
                        <p className={"tag-name"}>{title}<img/></p>
                    </div>
                    <Swiper
                        key={id}
                        breakpoints={{
                            // when window width is <1100px (480px)
                            0: {
                                slidesPerView: 2.4, //подобрано для ширины окна 140px;
                            },
                            425: {
                                slidesPerView: 2.5, //подобрано для ширины окна 140px;
                            },
                            // when window width is 1100px<w<1600px (1366px)
                            1100: {
                                slidesPerView: 5.637, //Подобрано таким образом, чтобы div одного слайда был 207px
                            },
                            // when window width is 1600px<w<1920px (1920px)
                            1600: {
                                slidesPerView: 6,
                            },
                        }}
                        freeMode={true}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[FreeMode, Pagination]}
                        className="mySwiper"
                    >
                        {ListCards}
                        <SwiperSlide onClick={() => {
                            goCategory()
                        }}>
                            <div className={"swiperSeeMoreContainer"}>
                                <p className={"swiperSeeMoreContainer__text"}>Больше<br/> товаров в каталоге</p>
                                <div className={"swiperSeeMoreContainer__button"}>В каталог</div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </>
            }
        </>
    );
}