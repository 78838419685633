import {useNavigate, useParams} from "react-router-dom";
import "./MyOrderPage.css"
import React, {useContext, useEffect, useState} from "react";
import {IMyOrders, IMyOrdersElement} from "../../../../../models/models";
import CustomNavigateHeader from "../../../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import {CompareDates, getParsedDate, Ru, sendMessageAsync} from "../../../../../functions/functions";
import CabinetBuyerService from "../../../../../services/CabinetBuyerService";
import {SpaRoutes} from "../../../../../Routes/spaRoutes";
import FunctionButton from "../../../../customUI/FunctionButton/FunctionButton";
import {Context} from "../../../../../index";
import OrderStatus from "../../../../customUI/OrderStatus/OrderStatus";
import {OrderProductStatusEnum, OrderStatusEnum} from "../../../../../functions/Order/OrderFunctions";

export default function MyOrderPage() {
    const {store} = useContext(Context);
    const FunctionButtonStyle: React.CSSProperties = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "48px",
        fontSize: 13,
        lineHeight: "16px",
        borderRadius: 7,
        float: "right",
    };
    const PaymentOptions: { name: string; id: number; }[] =
        [{name: "Наличные", id: 1},
            {name: "Банковская карта", id: 2},
            {name: "Бонусы", id: 3},
            {name: "Со счета", id: 4},
            {name: "QR-код", id: 5},
            {name: "По терминалу", id: 6}];

    const [paymentButtonText, setPaymentButtonText] = useState("Выбрать способ оплаты");
    const [DataFromMyOrders, SetDataFromMyOrders] = useState<any>(null);
    const [statusOrder, setStatusOrder] = useState<number>(-1)
    const [StyleOptions, setStyleOptions] = useState<{
        style: string,
        picture: string,
        time: string,
        title: string
    }[]>([]);
    const navigate = useNavigate();
    const params = useParams();

    const getOrderData = () => {
        store.DataLoadingON();
        CabinetBuyerService.getOrder({Info1: params.id})
            .then((data) => {
                if (data.status === 200) {
                    SetDataFromMyOrders(data.data);
                    setStatusOrder(data.data.historyStatuses[data.data.historyStatuses.length - 1].status)
                    if (data.data.historyStatuses[data.data.historyStatuses.length - 1].statusName == "Выдан" && !data.data.payed) {
                        setPaymentButtonText("Ожидает оплаты");
                    } else if (data.data.payed) {
                        setPaymentButtonText("Оплачено");
                    }
                    setStyleOptions(styleLogic(data.data));
                    for (const key2 in PaymentOptions) {
                        if (PaymentOptions[key2].id === data.data.paymentOption) {
                            setPaymentButtonText(PaymentOptions[key2].name);
                        }
                    }
                }
            })
            .catch(() => store.ErrorON())
            .finally(() => store.DataLoadingOFF());
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        getOrderData()
    }, [params.id, store]);

    const sendMessageToSupplier = async () => {
        await sendMessageAsync(
            store.user.id,
            store.user.displayedName,
            `Вопрос по заказу №${DataFromMyOrders?.orderId}`,
            DataFromMyOrders?.sellerId
        )
            .catch(() => store.showDialog('Что-то пошло не так'))
            .then(() => navigate(SpaRoutes.CHAT_DIALOG_PAGE + `/:${DataFromMyOrders?.sellerId}/:1/:0`));
    };

    const TotalPrice = DataFromMyOrders?.orderProducts.reduce((sum: number, item: any) => {
        return item.price * item.amount;
    }, 0);

    const TotalPriceFormatted = Ru(TotalPrice || 0);

    const dateForDelivery = DataFromMyOrders && getParsedDate(CompareDates(DataFromMyOrders.orderProducts.map((item: any) => item.deliveryDate)), true, false);
    const AdressForDelivery = DataFromMyOrders?.destination ? DataFromMyOrders?.destination?.city + ', ул. ' + (DataFromMyOrders?.destination?.street) + ', д. ' + (DataFromMyOrders?.destination?.number) : 'Не указан';

    const styleLogic = (data: any) => {
        let StyleOptions = [
            {style: 'myorderpage-green-status', picture: "/Pictures/VectorOpenOrder.svg", title: "Открыт", time: ''},
            {style: 'myorderpage-black-status', picture: "/Pictures/VectorAccepted.svg", title: "Принят", time: ''},
            {
                style: 'myorderpage-black-status',
                picture: "/Pictures/VectorReadyToGet.svg",
                title: "Готов к выдаче",
                time: ''
            },
            {style: 'myorderpage-black-status', picture: "/Pictures/Доставка.svg", title: "Доставка", time: ''},
            {style: 'myorderpage-black-status', picture: "/Pictures/Выдан.svg", title: "Выдан", time: ''},
            {style: 'myorderpage-black-status', picture: "/Pictures/VectorPayedOrder.svg", title: "Оплачен", time: ''}
        ];

        if (data?.statusChanges) {
            data.statusChanges.forEach((statusChange: any) => {
                const {status, timestamp} = statusChange;
                let statusIndex;
                let statusStyle = 'myorderpage-black-status';

                // Определим индекс и цвет стиля для текущего статуса
                switch (status) {
                    case "Открыт":
                        statusIndex = 0;
                        break;
                    case "Принят":
                        statusIndex = 1;
                        break;
                    case "Готов к выдаче":
                        statusIndex = 2;
                        break;
                    case "Доставка":
                        statusIndex = 3;
                        break;
                    case "Выдан":
                        statusIndex = 4;
                        break;
                    case "Оплачен":
                        statusIndex = 5;
                        break;
                    default:
                        return;
                }

                if (timestamp) {
                    StyleOptions[statusIndex].time = getParsedDate(timestamp, false, true);
                    StyleOptions[statusIndex].style = statusStyle;
                }
            });
        }

        return StyleOptions;
    };

    const Statuslist = StyleOptions.map((item) => {
        return (
            <div className="myorderpage-status-list-wrapper">
                <div>
                    <div className={`myorderpage-status-logo ${item.style}`}>
                        <img className="myorderpage-status-logo-picture" src={item.picture} alt=""/>
                    </div>
                </div>
                <div style={{height: "35px", display: "grid", alignItems: "center"}}>
                    <span className="myorderpage-status-title">{item.title}</span>
                    {(item.style !== 'myorderpage-black-status') &&
                        <span className="myorderpage-status-time">{item.time}</span>}
                </div>
            </div>
        );
    });

    const cancelOrder = () => {
        store.showDialog("Вы точно хотите отменить весь заказ?", true, "Отмена заказа")
            .then(() => {
                if(store.dialog.dialogResult){
                    store.DataLoadingON()
                    CabinetBuyerService.cancelOrder({Info1: DataFromMyOrders.orderId})
                        .then((response) => {getOrderData()})
                        .catch(() => store.showDialog("Что-то пошло не так"))
                        .finally(() => store.DataLoadingOFF())
                }
            })
    }

    const recieveOrder = () => {
        store.showDialog("Вы точно хотите подтвердить получение заказа?", true, "Получение заказа")
            .then(() => {
                if(store.dialog.dialogResult){
                    store.DataLoadingON()
                    CabinetBuyerService.recieveOrder(DataFromMyOrders.orderId)
                        .then((response) => {getOrderData()})
                        .catch(() => store.showDialog("Что-то пошло не так"))
                        .finally(() => store.DataLoadingOFF())
                }
            })
    }

    const renderStatusOrButton = (productStatus: number, orderProductId: string) => {
        if (productStatus === OrderProductStatusEnum.CanceledBuyer) {
            return <div style={{color: "#8E4646", textDecoration: "none"}}>Ожидание отмены</div>;
        }

        if (productStatus === OrderProductStatusEnum.ApproveCanceled) {
            return <div style={{color: "#8E4646", textDecoration: "none"}}>Отменён</div>;
        }

        // Если заказ ещё не выдан (до статуса Received), можно отменить
        if (statusOrder != OrderStatusEnum.Received && statusOrder != OrderStatusEnum.Closed) {
            return (
                <span className="myorderpage-fourth-column-red" onClick={() => cancelOrderProduct(orderProductId)}>
                    Отменить
                </span>
            );
        }

        return null;
    };

    const cancelOrderProduct = (orderProductId: string) => {
        store.showDialog("Вы точно хотите полностью отменить позицию?", true, "Отмена позиции")
            .then(() => {
                if(store.dialog.dialogResult){
                    let data = {
                        Info1: DataFromMyOrders.orderId,
                        Info2: orderProductId
                    }
                    store.DataLoadingON()
                    CabinetBuyerService.cancelOrderProduct(data)
                        .then(() => getOrderData())
                        .catch(() => store.showDialog("Что-то пошло не так"))
                        .finally(() => store.DataLoadingOFF())
                }
            })
    }

    return (
        <>
            {!store.isError && !store.isDataLoading && (
                <>
                    <CustomNavigateHeader
                        title={`Заказ №${DataFromMyOrders?.orderId}`}
                        trashClickHandler={() => null}
                        withTrash={false}
                    />

                    <p className="myorderpage-upper-title">Информация о заказе</p>

                    <OrderStatus
                        historyStatuses={DataFromMyOrders?.historyStatuses}
                        deliveryType={DataFromMyOrders?.destination?.destinationOption == 2 ? "delivery" : "pickup"}
                        isPayed={DataFromMyOrders?.payed}
                        payedDate={DataFromMyOrders?.payedDate}
                    />

                    <div className="myorderpage-main-div">
                        <span className="myorderpage-string">
                            <span className="myorderpage-title-string">Дата оформления заказа:</span>
                            {getParsedDate(DataFromMyOrders?.startDate, true, false)}
                        </span>
                        <span className="myorderpage-string">
                            <span className="myorderpage-title-string">Продавец:</span>
                            {DataFromMyOrders?.seller?.displayedName || "-"}
                        </span>
                        <span className="myorderpage-string">
                            <span className="myorderpage-title-string">Телефон продавца:</span>
                            {DataFromMyOrders?.seller?.phoneNumber || "-"}
                        </span>
                        <span className="myorderpage-string">
                            <span className="myorderpage-title-string">Тип доставки:</span>
                            {DataFromMyOrders?.destination?.destinationOption == 2 ? "Курьером" : "Самовывоз"}
                        </span>
                        <span className="myorderpage-string">
                            <span className="myorderpage-title-string">
                                {DataFromMyOrders?.destination?.destinationOption == 2 ? "Адрес доставки:" : "Адрес самовывоза:"}
                            </span>
                            {AdressForDelivery}
                        </span>
                        <span className="myorderpage-string">
                        <span className="myorderpage-title-string">Желаемое время доставки:</span>
                            {DataFromMyOrders?.startDate && DataFromMyOrders?.endDate ? (
                                    getParsedDate(DataFromMyOrders.startDate, true, false) !== getParsedDate(DataFromMyOrders.endDate, true, false) ? (
                                        // Если даты совпадают, но время разное - показываем интервал времени
                                        <>
                                            {getParsedDate(DataFromMyOrders.startDate, true, false)}, {getParsedDate(DataFromMyOrders.startDate, false, true)} - {getParsedDate(DataFromMyOrders.endDate, false, true)}
                                        </>
                                    ) : (
                                        // Если даты и время совпадают - указываем "в течение дня"
                                        `${getParsedDate(DataFromMyOrders.startDate, true, false)}, в течение дня`
                                    )
                                )
                                : (
                                    // Если даты отсутствуют
                                    "-"
                                )}
                        </span>

                        <div className="myorderpage-order-list">
                            <div className="myorderpage-header-table">
                                <span className="myorderpage-title-string">Товары в заказе:</span>
                                {[1, 2, 3, 4, 5, 8].includes(statusOrder) &&
                                    DataFromMyOrders?.orderProducts.some((product: any) => {
                                        const status = product.historyStatuses[product.historyStatuses.length - 1]?.status;
                                        return status !== 0 && status !== 1;
                                    }) && (
                                        <div className="myorderpage-cancel-order" onClick={() => cancelOrder()}>
                                            Отменить весь заказ
                                        </div>
                                    )
                                }

                            </div>
                            <table className="myorderpage-table">
                                {DataFromMyOrders?.orderProducts.map((item: any) => (
                                    <tr key={item.orderProductId}
                                        style={item.cancelled ? {backgroundColor: "rgb(153 153 153 / 34%)"} : {}}
                                    >
                                        <td className="myorderpage-columns myorderpage-first-column">{item.name}</td>
                                        <td className="myorderpage-columns myorderpage-second-column">{item.amount} шт.</td>
                                        <td className="myorderpage-columns myorderpage-third-column">
                                            {Intl.NumberFormat("ru", {
                                                style: "currency",
                                                currency: "RUB"
                                            }).format(item.price * item.amount)}
                                        </td>
                                        {/*<td className="myorderpage-columns myorderpage-fourth-column">
                                            {item.cancelled ? <div style={{color: "#8E4646"}}>отменён</div> : ""}
                                        </td>*/}
                                        <td className="myorderpage-columns myorderpage-fourth-column">
                                            {renderStatusOrButton(item?.historyStatuses?.slice(-1)[0]?.status, item.orderProductId)}
                                        </td>
                                    </tr>
                                ))}
                            </table>
                        </div>
                        <span className="myorderpage-title-string">
                            Комментарий к заказу
                        </span>
                        <span className="myorderpage-comment">
                            {DataFromMyOrders?.comment || "Комментарий к заказу не указан"}
                        </span>

                        <span className="myorderpage-title-string">
                            Комментарий к доставке
                        </span>
                        <span className="myorderpage-comment">
                            {DataFromMyOrders?.destination?.comment || "Комментарий к доставке не указан"}
                        </span>

                        <div id="myorderpage-sum-of-order">
                            <span>Сумма:</span>
                            <img id="img_dashed" src="/Pictures/Line 12.png" alt="line"/>
                            <span id="myorderpage-price-of-order">{TotalPriceFormatted}</span>
                        </div>
                        <div style={{
                            textAlign: "right",
                            marginTop: "10px",
                            fontSize: "11px",
                            width: "185px",
                            marginBottom: "8px",
                            marginLeft: "auto"
                        }}>
                            Информация для поставщика о способе оплаты заказа:
                        </div>
                        <div className="myorderpage_bottom_buttons">
                            <div className="myorderpage-payment-button" onClick={sendMessageToSupplier}>
                                <FunctionButton text="Начать чат с поставщиком" onClickHandler={() => {
                                }} style={FunctionButtonStyle}/>
                            </div>
                            {(statusOrder === 3 || statusOrder === 5) &&
                                <div className="myorderpage-confirm-receiving" onClick={() => recieveOrder()}>
                                    Подтвердить получение
                                </div>
                            }
                            <div className="myorderpage-payment-button"
                                 onClick={() => navigate(SpaRoutes.MYORDER_PAYMENT + `/${DataFromMyOrders?.orderId}/${TotalPriceFormatted}/${DataFromMyOrders?.paymentOption}`)}
                            >
                                <FunctionButton text={paymentButtonText} onClickHandler={() => {
                                }} style={FunctionButtonStyle}/>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}