import React, {useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import './Header.css'
import '../MenuSticky/Menu/Menu.css'
import Burger from "./Burger/Burger";
import {SpaRoutes} from "../../../../Routes/spaRoutes";
import {Context} from "../../../../index";
import {observer} from "mobx-react-lite";


interface Interface {
    CartitemsNumber:number;
    unread:boolean;
}

function Header({CartitemsNumber,unread}:Interface) {
    const {store} = useContext(Context);
    const onClickHandler=()=>{
        navigator.clipboard.writeText(window.location.href)
        setTimeout(()=>{
            store.showDialog('Ссылка на текущую страницу была скопирована в буфер обмена').then(()=>{})
        }, 500)
    }

    useEffect(() => {
        if (store.isHubOn) {
            store.hubConnection.on("UpdateCounter", (forChat: string) => {
                if(!window.location.href.includes(forChat)){
                    store.updateUnread(true);
                }
            });
        }
    }, [store.isHubOn]);

    return(
        <div className={"header-div"}>
            <div id="Hamburger-and-share">
                <Burger />
                {/*<img
                    id="img-share"
                    src="/Pictures/Перессылка.svg"
                    onClick={onClickHandler}
                />*/}
            </div>
            <div id="logo-and-homeButton">
                <Link id="img-home-header" to={SpaRoutes.TRAINING}>
                    <img src="/Pictures/cabinet-book-open.svg" />
                </Link>
                <Link id="img-logo-header" to={"/"} onClick={() => store.FiltersSave("-1", "", "-1", "", [])}>
                    <img src="/Pictures/Лого.svg" />
                </Link>
            </div>

            <div id={"header-buttons"}>
                <Link className={"a-link headerOrders"} to={SpaRoutes.MY_ORDERS_ACTIVE}>
                    <img src="/Pictures/Заказы.svg" />
                </Link>
                <Link className={"a-link headerCart"} to={SpaRoutes.CART}>
                    <img style={{width:"100%"}} src="/Pictures/Корзина.svg" />
                    {CartitemsNumber!=0&&
                        <span>
                            <p className={"header-p"}>{CartitemsNumber}</p>
                        </span>
                    }
                </Link>
                {unread?
                    <Link className={"headerNotification"} to={SpaRoutes.CUSTOMER_CHAT_LIST}>

                        <img style={{width:"inherit",height:"inherit"}} src={"/Pictures/Уведомление_2.png"}/>

                    </Link>:
                    <Link className={"headerNotification"} to={SpaRoutes.CUSTOMER_CHAT_LIST}>

                        <img style={{width:"inherit",height:"inherit"}}  src="/Pictures/Уведомление_1.png"/>

                    </Link>
                }
            </div>
        </div>
    )
}export default observer (Header);