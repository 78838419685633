import React, {useContext, useEffect, useRef, useState} from "react";
import {ISupplierAdress} from "../../../models/supplier/supplier";
import {ICity, IDropdownOption, IFilter, IRegion} from "../../../models/models";
import "./CustomDropdown.css";
import {event} from "jquery";
import {ITimeDropdown} from "../../../models/OrderFormation/IOrderFormation";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import {IFilterBoxGroups} from "../../Supplier_UI_kit/Filter4Magazines/SelectFilterBox";
import CustomDropdown from "./CustomDropdown";
import {Context} from "../../../index";
import {Link, useNavigate, useParams} from "react-router-dom";
import AdminService from "../../../services/AdminService";
import MultiSelectDropDown from "./MultiSelectDropDown";
import {SpaRoutes} from "../../../Routes/spaRoutes";
import CustomNavigateHeader from "../CustomNavigateHeader/CustomNavigateHeader";

interface Interface {
    filters: IFilter[],
    selectedIds?: string[],
    notBlockedIds?: string[],
    updateSelectedIds: (val: string[]) => void,
    disable?: boolean
}

const FilterDropDowns = ({filters, selectedIds, notBlockedIds, updateSelectedIds, disable}: Interface) => {
    let filterBox: any[] = []
    let sortFilter = filters.sort((a: any, b: any) => {
        if (a.attrSorter > b.attrSorter) return 1
        if (a.attrSorter < b.attrSorter) return -1
        return 0
    })
    sortFilter.forEach((filter) => {
        if (filterBox.every((fb) => {
            return fb.id !== filter.filterId
        })) {
            filterBox.push({
                id: filter.filterId,
                name: filter.filterName,
                sorter: filter.filterSorter,
                filterAttr: []
            })
        }
    })
    let sortedFilterBox = filterBox.sort((a: any, b: any) => {
        if (a.sorter > b.sorter) return 1
        if (a.sorter < b.sorter) return -1
        return 0
    })
    sortFilter.forEach((filter) => {
        sortedFilterBox[sortedFilterBox.findIndex((item) => {
            return item.id === filter.filterId
        })].filterAttr
            .push({
                key: filter.attrId,
                name: filter.attrName,
                sorter: filter.attrSorter
            })

    })
    sortedFilterBox.forEach((item) => {
        item.filterAttr = item.filterAttr.sort((a: any, b: any) => {
            if (a.sorter > b.sorter) return 1
            if (a.sorter < b.sorter) return -1
            return 0
        })
    })
    const {store} = useContext(Context)
    const [filterGroup, setFilterGroup] = useState<any[]>()
    const [selectedFilters, setSelectedFilters] = useState<IFilter[]>([])

    useEffect(() => {
        let fg: any[] = []
        let selection: any[] = []
        filters.forEach((filt) => {
            if (!(selectedIds) || selectedIds.some((id) => {
                return filt.attrId === id
            })) {
                selection.push(filt)
            }
            if (fg.every((item) => {
                return item.id !== filt.filterId
            })) {
                fg.push({
                    id: filt.filterId,
                    name: filt.filterName,
                    sorter: filt.filterSorter,
                    attributes: [{id: filt.attrId, name: filt.attrName, sorter: filt.attrSorter}]
                })
            } else {
                fg.forEach((item) => {
                    if (item.id === filt.filterId) {
                        item.attributes.push({id: filt.attrId, name: filt.attrName, sorter: filt.attrSorter})
                    }
                })
            }
        })
        fg.forEach((filt) => {
            filt.attributes = filt.attributes.filter((item: any) => {
                return item.id !== "no"
            }).sort((a: any, b: any) => {
                if (a.sorter > b.sorter) return 1
                if (a.sorter < b.sorter) return -1
                return 0
            })
        })
        setSelectedFilters(selection)
        setFilterGroup(fg.sort((a: any, b: any) => {
            if (a.sorter > b.sorter) return 1
            if (a.sorter < b.sorter) return -1
            return 0
        }));
    }, [filters])

    const changeSelection = (key: string) => {
        filters.forEach((fil) => {
            if (fil.attrId === key) {
                if (selectedFilters.some((sefil) => {
                    return sefil.attrId === key
                })) {
                    setSelectedFilters(selectedFilters.filter((filfil) => {
                        return filfil.attrId !== key
                    }))
                    updateSelectedIds(selectedFilters.filter((filfil) => {
                        return filfil.attrId !== key
                    }).map((item) => {
                        return item.attrId
                    }))
                } else {
                    if ((notBlockedIds && notBlockedIds.some((id) => {
                        return id === key
                    })) || notBlockedIds === undefined) {
                        setSelectedFilters(selectedFilters.concat([fil]))
                        updateSelectedIds(selectedFilters.concat([fil]).map((item) => {
                            return item.attrId
                        }))
                    }
                }
            }
        })
    }

    const FILTERS = filterGroup?.map((filter, index) => {
        return (
            <div key={index + filter.name}
                style={{marginBottom: "10px"}}>
                <div style={{display: "flex", width: "600px", maxWidth: "calc(100% - 0px)"}}>
                    <MultiSelectDropDown
                        options={filter.attributes.map((item: any) => {
                            let notBlocked = (!(notBlockedIds && !notBlockedIds.some((id) => {
                                return id === item.id
                            })))
                            return {key: item.id, value: item.name, name: item.name, notBlocked: notBlocked}
                        }).sort((a: any, b: any) => {
                            if (a.notBlocked > b.notBlocked) return -1
                            if (a.notBlocked < b.notBlocked) return 1
                            return 0
                        })}
                        selectedOptions={filter.attributes.map((item: any) => {
                            let notBlocked = (!(notBlockedIds && !notBlockedIds.some((id) => {
                                return id === item.id
                            })))
                            if (selectedFilters.some((ele) => {
                                return ele.attrId === item.id
                            })) return {key: item.id, value: item.name, name: item.name, notBlocked: notBlocked}
                            else return {}
                        }).filter((elem: any) => {
                            return !!elem.key
                        })}
                        displayedName={filter.name}
                        onChangeHandler={changeSelection}
                        dropdownSize={'small'}
                        closeAfterSelection={true}
                        disabled={disable}
                    />
                </div>
                <div style={{display: "flex"}}>{filter.attributes?.map((attr: any, index: any) => {

                    return (
                        <div
                            key={index + attr.id}
                            style={selectedFilters.some((filter) => {
                                return filter.attrId === attr.id
                            }) ? {display: "block"} : {display: "none"}}
                            className={""}
                        >
                            <div
                                className={"admin-filter-basic " +
                                    ((notBlockedIds && !notBlockedIds.some((id) => {
                                        return id === attr.id
                                    })) ? "dropdown-item-display-back-grey" : "")
                                }
                            >
                                {attr.name}
                            </div>
                        </div>
                    )
                })}
                </div>
            </div>
        )
    })

    return (
        <>{(!store.isDataLoading) &&
            <div className={""}>
                <div className={"admin-filters-container"}>
                    {FILTERS}
                </div>
            </div>
        }</>
    )
}
export default FilterDropDowns;