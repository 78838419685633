import React, {useContext, useEffect, useRef, useState} from "react";
import "./MagazineEdit.css"

import {ICity, IDropdownOption, IRegion, IMagazineData} from "../../../../models/models";
import "react-datepicker/dist/react-datepicker.css";
import {Context} from "../../../../index";
import CustomDropdown from "../../../customUI/CustomDropdown/CustomDropdown";
import CustomInput from "../../../customUI/CustomInput/CustomInput";
import CabinetSellerService from "../../../../services/CabinetSellerService";
import HomeService from "../../../../services/HomeService";
import {useNavigate, useParams} from "react-router-dom";
import CustomNavigateHeader from "../../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import {wait} from "@testing-library/user-event/dist/utils";
import {SpaRoutes} from "../../../../Routes/spaRoutes";
import {BeatLoader} from "react-spinners";
import KladrInputRegion from "../../../customUI/CustomDropdown/KladrInputRegion";
import KladrInputCity from "../../../customUI/CustomDropdown/KladrInputCity";


function MagazineEdit() {
    let {id, connectedId} = useParams();
    const {store} = useContext(Context)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [magazineData, setMagazineData] = useState<IMagazineData>({
        dropAdressId: "-1",
        street: "",
        number: "",
        postCode: undefined,
        city: "",
        cityId: "",
        region: "",
        regionId: "",
        isVirtual: false,
        virtualRegionId: ""
    });

    const [regionSuccess, setRegionSuccess] = useState<string>("false")

    const [citySuccess, setCitySuccess] = useState<string>("false")

    const [streetError, setStreetError] = useState(false)
    const [streetErrorMsg, setStreetErrorMsg] = useState<any>([])

    const [numberError, setNumberError] = useState(false)
    const [numberErrorMsg, setNumberErrorMsg] = useState<any>([])

    /*const [postIndexError, setPostIndexError] = useState(false)
    const [postIndexErrorMsg, setPostIndexErrorMsg] = useState<any>([])*/

/*    const [regionIdError, setRegionIdError] = useState(false)
    const [regionIdErrorMsg, setRegionIdErrorMsg] = useState<any>([])

    const [virtualRegionIdError, setVirtualRegionIdError] = useState(false)
    const [virtualRegionIdErrorMsg, setVirtualRegionIdErrorMsg] = useState<any>([])*/

/*    const [cityIdError, setCityIdError] = useState(false)
    const [cityIdErrorMsg, setCityIdErrorMsg] = useState<any>([])*/

    const [statusChangesCompanyData, setStatusChangesCompanyData] = useState("")

    const getMagazine = () => {
        if (id === "-1" && connectedId !== "-1") {
            setMagazineData({
                ...magazineData,
                isVirtual: true,
                forMagazineID: connectedId
            })
        } else if (id && id !== "-1") {
            store.DataLoadingON()
            CabinetSellerService.getSingleMagazineInfo(id)
                .then(response => {
                    let fixdata = response.data;
                    if (response.data["postCode"] == 0) fixdata["postCode"] = "";
                    setMagazineData(fixdata);
                })
                .then(() => store.DataLoadingOFF())
        }
    }

    useEffect(() => {
        getMagazine()
    }, [])

    const checkError = (value: string | any, setError: any, setErrorMsg: any, message: string | any, type: number) => {
        if (type === 0 && value === undefined || value === "" || value === null) {
            setError(true)
            setErrorMsg(["Пустое поле"])
            return true
        } else if (type === 1 && /_/.test(value as string)) {
            setError(true)
            setErrorMsg([message])
            return true
        } else if (type === 2 && !/@/.test(value as string)) {
            setError(true)
            setErrorMsg([message])
            return true
        } else if (type === 3 && value?.trim().split(".").length !== 3) {
            setError(true)
            setErrorMsg([message])
            return true
        } else if (type === 4 && value.trim().split(" ").length < 3) {
            setError(true)
            setErrorMsg([message])
            return true
        } else {
            setError(false)
            return false
        }
    }

    const handleInputChange = (e: any) => {
        const {name, value} = e.target;

        setMagazineData({
            ...magazineData,
            [name]: value
        });
    };

    const onRegionsSelectChange = (name: string, key: string) => {
        console.log(name, key)
        setMagazineData({
            ...magazineData,
            region: name,
            regionId: key,
            city: ""
        });
    }

    const onVirtualRegionsSelectChange = (name: string, key: string) => {
        setMagazineData({
            ...magazineData,
            virtualRegion: name,
        });
    }

    const onCitySelectChange = (val: string) => {
        setMagazineData({
            ...magazineData,
            city: val
        });
    }

    useEffect(() => {
        setTimeout(function () {
            setStatusChangesCompanyData("")
        }, 5000);
    }, [statusChangesCompanyData])

    const navigate = useNavigate()

    const uploadMagazineData = () => {
        if ((magazineData["isVirtual"] && regionSuccess === "true" && magazineData.virtualRegion) ||
            (!magazineData["isVirtual"] && regionSuccess === "true" && citySuccess === "true" && magazineData.region && magazineData.city)) {

            if(id === "-1") {
                store.DataLoadingON()
                let data = {}
                if(magazineData.isVirtual){
                    data = {
                        IsVirtual: magazineData.isVirtual,
                        ForMagazineID: connectedId,
                        VirtualRegion: magazineData.virtualRegion
                    }
                }else {
                    data = {...magazineData}
                }

                CabinetSellerService.createMagazine(data)
                    .then(response => {
                        setStatusChangesCompanyData("Успешно");
                        wait(2000).then(() =>
                            navigate(-1)
                        );
                    })
                    .catch((error) => {
                        if (error.response)
                            setStatusChangesCompanyData("Что-то пошло не так, попробуйте позже еще раз ")
                    })
                    .finally(() => store.DataLoadingOFF())
            }else{
                store.DataLoadingON()

                let data = {}
                if(magazineData.isVirtual){
                    data = {
                        DropAdressId: id,
                        IsVirtual: magazineData.isVirtual,
                        ForMagazineID: connectedId,
                        VirtualRegion: magazineData.virtualRegion
                    }
                }else {
                    data = {...magazineData}
                }

                CabinetSellerService.updateMagazine(data)
                    .then(response => {
                        setStatusChangesCompanyData("Успешно");
                        wait(2000).then(() =>
                            navigate(-1)
                        );
                    })
                    .catch((error) => {
                        if (error.response)
                            setStatusChangesCompanyData("Что-то пошло не так, попробуйте позже еще раз ")
                    })
                    .finally(() => store.DataLoadingOFF())
            }
        }
    }
    /*const checkErrorsMagazine = (_callback: () => void) => {
        let errors = false
        if (!magazineData["isVirtual"]) {
            if (checkError(magazineData["street"], setStreetError, setStreetErrorMsg, "", 0)) errors = true
            if (checkError(magazineData["number"], setNumberError, setNumberErrorMsg, "", 0)) errors = true
            if (checkError(magazineData["postCode"], setPostIndexError, setPostIndexErrorMsg, "Неверно введен почтовый индекс", 1)) errors = true
            if (checkError(magazineData["virtualRegionId"], setRegionIdError, setRegionIdErrorMsg, "Не выбран регион", 0)) errors = true
            if (checkError(magazineData["cityId"], setCityIdError, setCityIdErrorMsg, "Не выбран город", 0)) errors = true
        } else {
            if (checkError(magazineData["virtualRegionId"], setVirtualRegionIdError, setVirtualRegionIdErrorMsg, "Не выбран регион", 0)) errors = true
        }
        if (!errors) {
            _callback()
        }
    }*/
    const BtnMagazineClick = () => {
        uploadMagazineData()
    }

    return (
        <div className={"magazin-edit-container"}>
            <CustomNavigateHeader title={id === "-1" ? "Добавить новый склад" : "Редактировать склад"}
                                  trashClickHandler={() => {
                                  }} withTrash={false}/>


            {magazineData["isVirtual"] ?
                <div className={"magazin-edit-organization-data"}>
                    <div className={"magazin-edit-inputs"}>
                        <div className={"magazin-edit-input-text"}>Регион</div>
                        <div className={"magazin-edit-select-component-input"}>
                            <KladrInputRegion
                                styleDropdownDisplay={"magazin-edit-dropDown-input"}
                                icon={true}
                                value={magazineData.virtualRegion}
                                dropdownSize={"large"}
                                placeholder={"Регион"}
                                valueSetter={(val) => setMagazineData({...magazineData, virtualRegion: val})}
                                successSetter={setRegionSuccess}
                                onChangeHandler={onVirtualRegionsSelectChange}
                            />
                        </div>
                    </div>
                    <div className={"magazin-edit-saveChanges-container"}>
                        <div className={"magazin-edit-saveChanges-status"}
                             style={{color: statusChangesCompanyData === "Успешно" ? "green" : "#db2020"}}>{statusChangesCompanyData}</div>
                        <button className={"magazin-edit-saveChanges-button"} onClick={BtnMagazineClick}>
                            Сохранить изменения
                        </button>
                    </div>
                </div>
                :
                <div className={"magazin-edit-organization-data"}>
                    <div className={"magazin-edit-inputs"}>
                        <div className={"magazin-edit-half-inputs"}>
                            <div className={"magazin-edit-address-inputs"}>

                                <div className={"magazin-edit-address-input-street"}>
                                    <div className={"magazin-edit-input-text"}>Адрес склада</div>

                                    <CustomInput
                                        name={"street"}
                                        value={magazineData["street"]}
                                        error={streetError}
                                        errorMsg={streetErrorMsg}
                                        placeHolder={"Улица"}
                                        onChange={(event) => {
                                            handleInputChange(event)
                                            setStreetError(false)
                                        }}
                                        inputSize={"large"}
                                    />
                                </div>
                                <div className={"magazin-edit-address-input-numHome"}>
                                    <CustomInput
                                        name={"number"}
                                        value={magazineData["number"]}
                                        error={numberError}
                                        errorMsg={numberErrorMsg}
                                        placeHolder={"Дом"}
                                        onChange={(event) => {
                                            handleInputChange(event)
                                            setNumberError(false)
                                        }}
                                        inputSize={"large"}
                                    />
                                </div>
                            </div>
                            <div className={"magazin-edit-half-input"}>
                                <div className={"magazin-edit-input-text"}>Почтовый индекс</div>
                                <CustomInput
                                    name={"postCode"}
                                    type={"inputMask"}
                                    mask={"999999"}
                                    value={magazineData["postCode"]}
                                    onChange={(event) => {
                                        handleInputChange(event)
                                    }}
                                    placeHolder={"Почтовый индекс"}
                                    inputSize={"large"}
                                />
                            </div>
                        </div>

                        <div className={"magazin-edit-half-inputs"}>
                            <div className={"magazin-edit-half-input"}>

                                <div className={"magazin-edit-input-text"}>Регион</div>

                                <div className={"magazin-edit-select-component-input"}>
                                    <KladrInputRegion
                                        getRegionId={(id) => setMagazineData({...magazineData, regionId: id})}
                                        value={magazineData.region}
                                        dropdownSize={"large"}
                                        placeholder={"Регион"}
                                        valueSetter={(val) => setMagazineData({...magazineData, region: val})}
                                        successSetter={setRegionSuccess}
                                        onChangeHandler={onRegionsSelectChange}
                                    />
                                </div>
                            </div>
                            <div className={"magazin-edit-half-input"}>

                                <div className={"magazin-edit-input-text"}>Город</div>

                                <div className={"magazin-edit-select-component-input"}>
                                    <KladrInputCity
                                        regionId={magazineData.regionId}
                                        dropdownSize={"large"}
                                        value={magazineData.city}
                                        placeholder={"Город"}
                                        successSetter={setCitySuccess}
                                        valueSetter={(val) => setMagazineData({...magazineData, city: val})}
                                        onChangeHandler={onCitySelectChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"magazin-edit-saveChanges-container"}>
                        <div className={"magazin-edit-saveChanges-status"}
                             style={{color: statusChangesCompanyData === "Успешно" ? "green" : "#db2020"}}>{statusChangesCompanyData}</div>
                        <button className={"magazin-edit-saveChanges-button"} onClick={BtnMagazineClick}>
                            Сохранить изменения
                        </button>
                    </div>
                </div>
            }


        </div>
    )
}

export default MagazineEdit;