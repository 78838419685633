export const SpaRoutes = {
    UNAUTHORIZED: "/unauthorized",
    LOGIN: "/login",
    SHARE: "/share",
    MY_ORDERS_ACTIVE: "/myOrdersActive",
    MY_ORDERS_CLOSED: "/myOrdersClosed",
    CART: "/cart",
    NOTIFICATION: "/notification",
    REGISTER: "/register",
    LOGOUT: "/logout",
    FAVORITES: "/favorites",
    LAYOUT_OVERVIEW_DEALER: "/Layout/OverviewDealer",
    LAYOUT_NEWS: "/Layout/News",
    LAYOUT_CALENDAR: "/Layout/CalendarReklama",
    BUYER: "/buyer",
    BUYER_COMPANY: "/buyerCompany",
    SELLER: "/seller",
    PROFILE: "/profile",
    CATALOG_INDEX: "/catalogIndex",
    ORDER_FORMATION: "/orderformation",
    DO_SOMETHING_WITH_POINTS: "/doSomethingWithPoints",
    LINK_PAGE: "linkpage",
    SUPPLIER_PAGE: "/suppliersPage",
    COUPONS: "/coupons",
    CUSTOMER_CHAT_LIST: "/chat",
    SUPPLIER_CHAT: "/SupplierChat",
    CUSTOMERS: "/customers",
    SUB_CATEGORY: "/SubCatPage",
    CATEGORY: "/CatPage",
    RELATED_PRODUCTS: "/RelatedProducts",
    PRODUCT: "/ProductPage",
    HISTORYVIEWED: "/HistoryPage",
    MYORDER: "/myOrderPage",
    MYORDER_PAYMENT: "/myOrderPayment",
    CHAT_DIALOG_PAGE: "/chatWindow",
    CUSTOMER_INVOICES: "/customerInvoices",
    INVOICES_LIST: "/invoices",
    INVOICE_STATUS_7: "/invoices_status_7",
    INVOICES: "/invoices",
    INVOICE_STATUS_6: "/invoices_status_6",
    INVOICE_RETURNED: "/invoices_returned",
    SUP_CATALOG: "/supcatalog",
    INVOICE_CANCELED: "/invoices_canceled",
    SUPPLIER_PROFILE: "/supplierProfile",
    CABINET: "/cabinet",
    PRODUCT_DETAILS: "/productDetails",
    SUPPLIER_PRODUCTS: "/products",
    SUP_MAGAZINE: "/magazines",
    SUP_MAGAZINE_EDIT: "/magazineEdit",
    SELECT_PRODUCT: "/SelectProduct",
    ABOUT_US: '/aboutUs',
    EMAIL_CONFIRM: '/confirmation',
    SUP_ORDER_PAGE: "/SupplierOrderPage",
    BONUSES: "/bonuses",
    BONUSES_TRANSACTION_HISTORY: "/bonusesTransactionHistory",
    TRAINING: "/training",
    ADDTRAINING: "/addtraining",
    IN_PROGRESS: '/inprogress',
    SUP_CARDS_PAYMENT_HISTORY: "/myCardsPaymentHistory",
    SUPPLIER_RESERVATION_REKLAMA: "/reservationReklama",
    SUPPLIER_REKLAMA: "/supplierReklama",

    ADMIN_PRODUCT_DETAILS: "/adminProductDetails",
    ADMIN_SUPPLIERS: "/adminSuppliers",
    ADMIN_SUPPLIER: "/adminSupplier",
    ADMIN_SUPPLIER_MAGAZINE: "/magazines",
    ADMIN_BUYERS: "/adminBuyers",
    ADMIN_BUYER: "/adminBuyer",
    ADMIN_CATEGORIES: "/adminCategories",
    UNPROCESSED_PRODUCTS:"/unprocessedProducts",
    ADMIN_ROOT_PREVIEW: "/adminRootPreview",
    ADMIN_MAIN_SETTINGS: "/adminMainSettings",
    ADMIN_SUPPLIER_QP: "/adminSupplierQuestionPage",
    ADMIN_CARDS_PAYMENT_HISTORY: "/adminCardsPaymentHistory",
    ADMIN_CHAT:"/AdminChat",
    FILTERS:"/adminfilters",
    FILTER_EDIT:"/adminfilter_edit",
    ADMIN_MODERATION_REKLAMA:"/adminModerationReklama",
    ADMIN_REKLAMA:"/adminReklama",

    Buyer: {

    },
    Seller: {
        Order: "/order",
        OrderPayment: "/orderPayment",
        Orders:{
            Orders: "/orders",
            PickUp: {
                Open: "/orders/open/pickUp",
                Accepted: "/orders/accepted/pickUp",
                ReadyToGive: "/orders/readyToGive/pickUp",
                Received: "/orders/received/pickUp",
            },
            Delivery:{
                Open: "/orders/open/delivery",
                Accepted: "/orders/accepted/delivery",
                ReadyToDelivery: "/orders/readyToDelivery/delivery",
                Sending: "/orders/sending/delivery",
                Received: "/orders/received/delivery",
            },

            Closed: "/orders/closed",
            CanceledBuyer: "/orders/canceledBuyer",
            Canceled: "/orders/canceled",
        },
        Clients: "/clients"
    },
    Admin: {

    }
}
