import {useNavigate} from 'react-router-dom';
import React, {useContext, useEffect, useState} from "react";
import './BottomCartButton.css'
import {AxiosResponse} from "axios";
import {Context} from "../../../index";

interface Interface {
    link: string;
    state: any;
    ServiceFunction: (state: any) => void;
    forOrderFormation?: boolean;
}

export default function BottomCartButton({link, state, ServiceFunction, forOrderFormation = false}: Interface) {
    const navigate = useNavigate();
    const {store} = useContext(Context);

    const [irgendwas, setIrgendwas] = useState(false)

    useEffect(() => {
        if (forOrderFormation) {
            let val = false
            state.map((item: any, index: any) => {
                if (item.selectedTime && item.selectedAdress) {
                    val = true
                }
            })
            setIrgendwas(val)
        }
    }, [state]);

    const clickHandler = () => {
        let partState = state
        if (forOrderFormation) {
            partState = []
            state.map((item: any) => {
                if (item.selectedTime && item.selectedAdress) {
                    partState.push(item)
                }
            })
        }
        ServiceFunction(partState)

        // if(Object.keys(newstate).length != 0)
        //     if (handler) {
        //         newstate = handler(state)
        //     }
        //     if(link)
        //         navigate("/"+ link, { state: newstate })
    }

    const clickDisable = () => {
        store.showDialog("Чтобы продолжить, проверьте, что все необходимые данные заполнены")
    }

    return (<>
            {forOrderFormation ? irgendwas ?
                    <div id="form-an-order-wrapper">
                        <div id="form-an-order" onClick={clickHandler}>
                            <span>Сформировать заказ</span>
                        </div>
                    </div> :
                    <div id="form-an-order-wrapper">
                        <div id="form-an-order-passive" onClick={clickDisable}>
                            <span>Сформировать заказ</span>
                        </div>
                    </div>
                :
                <div id="form-an-order-wrapper">
                    <div id="form-an-order" onClick={clickHandler}>
                        <span>Сформировать заказ</span>
                    </div>
                </div>}
        </>

    )
}