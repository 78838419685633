import React, {useContext, useEffect, useRef, useState} from "react";
import AdminService from "../../../../services/AdminService";
import "./SupplierQuestionPage.css"
import CustomNavigateHeader from "../../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import {Context} from "../../../../index";
import CustomDropdown from "../../../customUI/CustomDropdown/CustomDropdown";
import {
    GalleryImage,
    IDropdownOption,
    IFilter,
    IRootProductQuestion,
    ISubCategory,
    ITopCategory
} from "../../../../models/models";
import CustomInput from "../../../customUI/CustomInput/CustomInput";
import {useNavigate, useParams} from "react-router-dom";
import FilterDropDowns from "../../../customUI/CustomDropdown/FilterDropDowns";
import {CustomIMG} from "../../../customUI/CustomIMG/CustomIMG";
import LoadProductImage from "../../../customUI/LoadProductImage/LoadProductImage";


export default function SupplierQP(){
    const {store} = useContext(Context);

    let {questionId} = useParams();
    if(questionId === undefined) questionId="0";

    useEffect(()=> {
        window.scrollTo(0, 0)
    },[])
    const navigate = useNavigate();

    const [productFile, setProductFile] = useState<File>();
    const [productPreview, setProductPreview] = useState<string>();
    const inputRef = useRef(null);

    const [productData,setProductData] = useState<IRootProductQuestion>({});
    const [oldProductData, setOldProductData] = useState<IRootProductQuestion>({})
    const [firstLoad, setFirstLoad] = useState<boolean>(false);

    // Состояния категории
    const [topCategoryName, setTopCategoryName] = useState("Категория")
    const [subCategoryName, setSubCategoryName] = useState("Подкатегория")
    const [loadedProductData, setLoadedProductData] = useState(false)
    const [loadedTopCategories, setLoadedTopCategories] = useState(false)
    const [loadedAll, setLoadedAll] = useState(false)
    const [topCategories, setTopCategories] = useState<ITopCategory[]>([]);
    const [subCategories, setSubCategories] = useState<ISubCategory[]>([]);
    const [arrayTopCat, setArrayTopCat] = useState<IDropdownOption[]>([])
    const [arraySupCat, setArraySubCat] = useState<IDropdownOption[]>([])
    const [filterArray, setFilterArray] = useState<IFilter[]>([])
    const [galleryImages, setGalleryImages] = useState<GalleryImage[]>([])
    const [galleryDeletedImages, setGalleryDeletedImages] = useState<number[]>([])


    const createFormData = (data: any) => {
        return Object.keys(data).reduce((formData, key) => {
            if(key === "galleryImagesFile"){
                data[key].forEach((item: string | Blob, index: any) => {
                    if(item) formData.append(`${key}[${index}]`, item);
                })
            }
            else{
                formData.append(key, data[key])
            }

            return formData
        }, new FormData())
    }

    const refactorProductData = (data: { [x: string]: any; }) => {
        for (const key in data) {
            if (data[key] === null || data[key] === undefined)
                delete data[key]
        }
        return data
    }

    useEffect(() => {

        if(questionId){
            store.DataLoadingON();
            AdminService.postQuestionRootProductInfo(questionId)
                .then((response)=>{
                    let data:IRootProductQuestion = response.data;
                    setProductData(data);
                    setOldProductData(data);
                    setProductPreview(response.data.imageUrl);
                    setGalleryImages(response.data.galleryImages??[])
                    setFirstLoad(true)
                })
                .catch(() => {
                    store.ErrorON();
                })
                .finally(()=>{
                    setLoadedProductData(true)
                })
        }
    }, [])

    useEffect(() => {
        if (topCategories) {
            let newArrayTopCat:IDropdownOption[] = []
            topCategories.map((item) => {
                newArrayTopCat.push({key: item.topCategoryId, name: item.name})
            });
            setArrayTopCat(newArrayTopCat)
        }
    }, [topCategories])

    useEffect(() => {
        if (subCategories) {
            let newArraySubCat:IDropdownOption[] = []
            subCategories.map((item) => {
                newArraySubCat.push({key: item.categoryId, name: item.name})
            });
            setArraySubCat(newArraySubCat)
        }
    }, [subCategories])

    useEffect(()=>{
        let filtarr:IFilter[] = []
        if(topCategories && subCategories){
            topCategories.map((c)=>{
                if (c.topCategoryId === subCategories[0].topCategoryId){
                    filtarr = filtarr.concat(c.filterAttributes)
                }
            })
            subCategories.map((c)=>{
                if (c.categoryId === productData["subCategoryId"]){
                    filtarr = filtarr.concat(c.filterAttributes)
                }
            })
        }
        let uniqueArray:IFilter[] = []
        filtarr.map( (filt) => {
            if (uniqueArray.every((item)=>item.attrId !==filt.attrId)){
                uniqueArray.push(filt)
            }
        })
        setFilterArray(uniqueArray)
    },[subCategoryName])

    useEffect(()=>{
        AdminService.getAllCategories()
            .then(response => {
                setTopCategories(response.data.topCategories.sort( (a:any, b:any)=> {
                    if (a.name < b.name) {
                        return -1;
                    }
                    if (a.name > b.name) {
                        return 1;
                    }
                    return 0;
                }))
                setLoadedTopCategories(true)
            })
            .catch(() => {
                store.ErrorON()
            })
            .finally(()=>{
                if(firstLoad)
                    store.DataLoadingOFF();
            })
    }, [firstLoad])

    const onTopCategorySelectChange = (val: string) => {
        const TopCategoryId = val;
        const topCategory = topCategories.find(item => item.topCategoryId === TopCategoryId);
        const subCategories = topCategory?.categories;
        if ((subCategories != null) && subCategories.length != 0) setSubCategories(subCategories);
        else setSubCategories([{categoryId: 'no', name: 'Нет подкатегорий',sorter:0,topCategoryId:val,filterAttributes:[]}]);
        setSubCategoryName("Подкатегория");
        setProductData({...productData, "subCategoryId": undefined})
    }

    const updateSelectedIds = (val:string[])=>{
        setProductData({
            ...productData,
            ["filterAttrIds"]:val
        });

    }

    const onSubCategorySelectChange = (val: string) => {
        setProductData({
            ...productData,
            ["subCategoryId"]: val
        });
    }
    useEffect(() => {
        if (topCategories) {
            let newArrayTopCat:IDropdownOption[] = []
            topCategories.map((item) => {
                newArrayTopCat.push({key: item.topCategoryId, name: item.name})
            });
            setArrayTopCat(newArrayTopCat)
        }
    }, [topCategories])

    useEffect(() => {
        if (subCategories) {
            let newArraySubCat:IDropdownOption[] = []
            subCategories.map((item) => {
                newArraySubCat.push({key: item.categoryId, name: item.name})
            });
            setArraySubCat(newArraySubCat)
        }
    }, [subCategories])

    useEffect(()=>{
        let filtarr:IFilter[] = []
        if(topCategories && subCategories){
            topCategories.map((c)=>{
                if (c.topCategoryId === subCategories[0].topCategoryId){
                    filtarr = filtarr.concat(c.filterAttributes)
                }
            })
            subCategories.map((c)=>{
                if (c.categoryId === productData["subCategoryId"]){
                    filtarr = filtarr.concat(c.filterAttributes)
                }
            })
        }
        let uniqueArray:IFilter[] = []
        filtarr.map( (filt) => {
            if (uniqueArray.every((item)=>item.attrId !==filt.attrId)){
                uniqueArray.push(filt)
            }
        })
        setFilterArray(uniqueArray)
    },[subCategoryName])

    //Handler for adding the question product to root products
    const AddHandler = () => {
        const choice = window.confirm("Вы уверены, что хотите добавить товар?");
        if(choice) {
            let mydata: any = {
                "productRootQuestionId": questionId,
                "productRootId": "0",
                "title": productData["title"],
                "subCategoryId": productData["subCategoryId"],
                "imageFile": productFile,
                "imageUrl":productData["imageUrl"],
                "galleryDeletedImages": galleryDeletedImages.length > 0? galleryDeletedImages : undefined,
                "galleryImagesFile": galleryImages.map((item)=> item.imageFile),
                "description": productData["description"],
                "filterAttrIds":productData["filterAttrIds"]
            }
            const data = createFormData(refactorProductData(mydata));

            store.DataLoadingON()
            AdminService.postProductRootCreate(data)
                .then(response => {
                    if (questionId && response.status === 200) {
                        AdminService.postDeleteRootQuestion(questionId)
                            .then(()=>{
                                alert("Коренной товар успешно добавлен")
                                navigate(-1)
                            })
                            .catch(()=>{store.ErrorON();})
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 501) {
                            alert("Такое название уже занято")
                        } else {
                            alert("Что-то пошло не так")
                        }
                    }
                })
                .finally(() => {
                    store.DataLoadingOFF()
                })
        }

    }

    //Handler for deleting the question
    const DeleteHandler = () => {
        const choice = window.confirm("Вы уверены, что хотите удалить запрос?");
        if(choice) {
            if (questionId !== undefined)
                AdminService.postDeleteRootQuestion(questionId)
                    .then((response) => {
                        alert("Данный запрос успешно удален")
                        navigate(-1)
                    })
                    .catch((error) => {
                        alert("При удалении запроса возникла ошибка"); //:\n" + `${error}`);
                    })
                    .finally(() => {
                        store.DataLoadingOFF();
                    })
        }
    }


    useEffect(() => {
        if (loadedProductData && loadedTopCategories && topCategories && subCategories) {


            let subCatId = productData["subCategoryId"]
            if (subCatId != undefined) {
                // don't touch
                const topCat = topCategories
                    .find(item => item.categories
                        .find((item: ISubCategory) => item.categoryId === subCatId))
                const subCat = topCat?.categories
                    .find((item: ISubCategory) => item.categoryId === subCatId)
                if (topCat) {
                    setTopCategoryName(topCat.name);
                    setSubCategoryName(subCat.name);
                    setSubCategories(topCat?.categories)
                }
            }

            setLoadedAll(true);
        }
    }, [loadedProductData, loadedTopCategories, topCategories, subCategories])


    //For using pictures from files
    /*useEffect(() => {
        if (!productFile) {
            setProductPreview(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(productFile)
        setProductPreview(objectUrl)
        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [productFile])*/

    const selectProductPicture = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files || event.target.files.length === 0) {
            setProductPreview(undefined);
            return
        }
        // I've kept this example simple by using the first image instead of multiple
        setProductFile(event.target.files[event.target.files.length-1]);
    };

    useEffect(() => {
        if (loadedProductData && loadedTopCategories && topCategories && subCategories) {


            let subCatId = productData["subCategoryId"]
            if (subCatId != undefined) {
                // don't touch
                const topCat = topCategories
                    .find(item => item.categories
                        .find((item: ISubCategory) => item.categoryId === subCatId))
                const subCat = topCat?.categories
                    .find((item: ISubCategory) => item.categoryId === subCatId)
                if (topCat) {
                    setTopCategoryName(topCat.name);
                    setSubCategoryName(subCat.name);
                    setSubCategories(topCat?.categories)
                }
            }

            setLoadedAll(true);
        }
    }, [loadedProductData, loadedTopCategories, topCategories, subCategories])


    const galleryChange = (preview: string | undefined, file: File | undefined, index: number) => {
        setGalleryImages((prevState) => {
            const newState = [...prevState];
            newState[index] = { ...newState[index], imageUrl: preview, imageFile: file};
            return newState;
        });
    }

    const onDeleteGalleryImage = (index: number) => {
        if (window.confirm("Вы точно хотите удалить это изображение?")) {
            let arrDeleted = galleryDeletedImages
            arrDeleted.push(index)
            setGalleryDeletedImages(arrDeleted);

            setGalleryImages((prevState) => {
                return prevState.map((image, i) =>
                    i === index
                        ? { ...image, imageUrl: undefined, imageFile: undefined }
                        : image
                );
            });
        }
    }

    useEffect(() => {
        console.log(galleryImages)
    }, [galleryImages]);

    return (
        <>{(!store.isError && !store.isDataLoading) &&
            <div className={"admin_qp_container"}>
                <CustomNavigateHeader title={"Обработка запросов на новые товары"} withTrash={false}/>
                <div className="admin_qp_main_container">
                    <div className="admin_qp_first_column">
                        <div>
                            <div className={"admin_qp_header_1"}>Поставщик, который сделал вопрос</div>
                            <div className={"admin_qp_dealer_info"}>
                                <div>{productData?.displayedName + ":"}</div>
                                <div>{productData?.dealerId}</div>
                            </div>
                            <div>
                                <div className={"admin_qp_header_2"}>Название</div>
                                <div className={"admin_qp_name"}>
                                    <CustomInput
                                        name = {"productTitle"}
                                        value={productData?.title}
                                        onChange={
                                            (event) => setProductData({...productData, ["title"]: event.target.value})
                                        }
                                    />
                                </div>
                            </div>
                            <div>
                                <div className={"admin_qp_header_2"}>Описание</div>
                                <div>
                                    <textarea
                                        className={"admin_qp-description-input"}
                                        value={productData?.description}
                                        onChange={
                                            (event) => setProductData({...productData, ["description"]: event.target.value})
                                        }
                                    />
                                </div>
                            </div>
                            <div className="admin_qp_small_content">
                                <div>
                                    <div className={"admin_qp_header_2"}>Категория</div>
                                    <div className={"admin_qp_dd"} style={{marginBottom:"30px"}}>
                                        <CustomDropdown
                                            options={arrayTopCat}
                                            dropdownSize={"large"}
                                            value={topCategoryName}
                                            valueSetter={setTopCategoryName}
                                            onChangeHandler={onTopCategorySelectChange}
                                            styleDropdownDisplay={"admin_qp_dd_display"}
                                            icon={true}
                                        />
                                    </div>
                                    <div className={"admin_qp_dd"} style={{marginBottom:"10px"}}>
                                        <CustomDropdown
                                            dropdownSize={"large"}
                                            options={arraySupCat}
                                            value={subCategoryName}
                                            valueSetter={setSubCategoryName}
                                            onChangeHandler={onSubCategorySelectChange}
                                            styleDropdownDisplay={"admin_qp_dd_display"}
                                            icon={true}
                                        />
                                    </div>
                                    <div className={"admin_qp_photo_div"}>
                                        <LoadProductImage
                                            onChange={(preview, file) => {
                                                setProductPreview(preview)
                                                setProductFile(file)
                                            }}
                                            previewProps={productPreview}
                                            title={"Основное фото"}

                                        />

                                        <LoadProductImage
                                            onChange={(preview, file) => {
                                                galleryChange(preview, file, 0)
                                            }}
                                            previewProps={galleryImages[0] ? galleryImages[0].imageUrl : undefined}
                                            title={"Галлерея"}
                                            canDelete={true}
                                            onDelete={() => {
                                                onDeleteGalleryImage(0)
                                            }}
                                        />
                                        <LoadProductImage
                                            onChange={(preview, file) => {
                                                galleryChange(preview, file, 1)
                                            }}
                                            previewProps={galleryImages[1] ? galleryImages[1].imageUrl : undefined}
                                            canDelete={true}
                                            onDelete={() => {
                                                onDeleteGalleryImage(1)
                                            }}
                                        />
                                        <LoadProductImage
                                            onChange={(preview, file) => {
                                                galleryChange(preview, file, 2)
                                            }}
                                            previewProps={galleryImages[2] ? galleryImages[2].imageUrl : undefined}
                                            canDelete={true}
                                            onDelete={() => {
                                                onDeleteGalleryImage(2)
                                            }}
                                        />
                                        <LoadProductImage
                                            onChange={(preview, file) => {
                                                galleryChange(preview, file, 3)
                                            }}
                                            previewProps={galleryImages[3] ? galleryImages[3].imageUrl : undefined}
                                            canDelete={true}
                                            onDelete={() => {
                                                onDeleteGalleryImage(3)
                                            }}
                                        />
                                        {/*<div className={"admin_qp_header_2"}>Картинка продукта</div>
                                        <div>
                                            <div className={"admin_qp-photo"}>
                                                <div  className={"admin_qp-photo-image"}
                                                      onClick={onBtnPictureClick}
                                                      onDrop={fileDrop}
                                                >
                                                    {productPreview?
                                                        <div className="admin_qp_view_first">
                                                            <img
                                                                src={productPreview}
                                                                alt={"product picture"}
                                                            />
                                                            <div className="admin_qp_mask">
                                                                <div className={"admin_qp_add_photo"}>
                                                                    <img src="/Pictures/SubtractAddPlus.svg" alt=""/>
                                                                    <div>Изменить</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className={"admin_qp_add_photo"}>
                                                            <img src="/Pictures/SubtractAddPlus.svg" alt=""/>
                                                            <div>Изменить</div>
                                                        </div>
                                                    }
                                                </div>
                                                <input
                                                    ref={inputRef}
                                                    style={{display: 'none'}}
                                                    type="file"
                                                    name={"profilePicture"}
                                                    onChange={selectProductPicture}
                                                    accept="image/*"
                                                />
                                            </div>
                                        </div>*/}
                                    </div>

                                    {filterArray&&filterArray.length>0&&<FilterDropDowns
                                        filters={filterArray}
                                        selectedIds={productData["filterAttrIds"]?productData["filterAttrIds"]:[]}
                                        updateSelectedIds={updateSelectedIds}
                                    />}

                                    <div className="admin_qp_preview">
                                        <div className={"admin_qp_header_1"}>Предпросмотр</div>
                                        <div className="product_card_container">
                                            <div className={"product_card"}>
                                                <div style={{display: "flex"}}>
                                                    <CustomIMG id="img_of_card" src={productPreview} />
                                                </div>
                                                <div id="text_cont">
                                                    <span id="text_of_card">{productData.title} </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className={"admin_qp_bottom_buttons"}>
                                            <div onClick={AddHandler}>Добавить товар в каталог</div>
                                            <div onClick={DeleteHandler}>
                                                <img src="/Pictures/Trash-white.svg" alt="trash"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }</>
    )
};