import React, {useEffect, useRef, useState} from "react";
import {ISupplierAdress} from "../../../models/supplier/supplier";
import {ICity, IDropdownOption, IRegion} from "../../../models/models";
import "./CustomDropdown.css";
import {event} from "jquery";
import {ITimeDropdown} from "../../../models/OrderFormation/IOrderFormation";
import useOnClickOutside from "../../../hooks/useOnClickOutside";

interface Interface {
    importantId?:boolean;
    options: IDropdownOption[];
    value?:string | IDropdownOption;
    valueSetter:(val:any)=>any;
    onChangeHandler:(val:any)=>any;
    dropdownSize?: "small" | "medium" | "large";
    styleDropdownDisplay?:string;
    styleDropdownList1?:string;
    styleDropdownList2?:string;
    icon?: boolean;
    error?: boolean;
    errorMsg?: [string] | any;
    disabled?: boolean;
    isRemoved?: boolean;
    key?: any
}

const CustomDropdown = ({importantId, options,value,valueSetter,onChangeHandler, styleDropdownDisplay = "", icon, error,dropdownSize='small', errorMsg,styleDropdownList1='',styleDropdownList2='', disabled=false, isRemoved, key}:Interface) => {
    const [hideMe, setHideMe] = useState("true");
    const listRef = useRef(null);

    const ref = useRef<HTMLDivElement | null>(null);
    useOnClickOutside(ref, () => setHideMe("true"));

     const dropdownHandler = (val:any)=> {
         setHideMe("true");
         if (val.key)
             if (val.key != "no") {
                 if (!importantId && typeof value !== "string") {
                     valueSetter({name: val.name, key: val.key})
                     onChangeHandler(val.key);
                 } else {
                     valueSetter(val.name)
                     onChangeHandler(val.key);
                 }
             }
     }
         const clickHandler = (event: any) => {
             if (!disabled) {
                 let dropdowns = document.getElementsByClassName("DropdownDisplay")
                 Array.prototype.forEach.call(dropdowns, function (item) {
                     if (event?.currentTarget.identifier != item && item.dataset["hideme"] === "false") {
                         item.click();
                     }
                 })
                 if (options.length > 0) {
                     hideMe == "true" ? setHideMe("false") : setHideMe("true");
                 } else {
                     setHideMe("true");
                 }
             }
         }

         const ListDropdown = options.map((item) => {
             return (
                 <div
                     className={`dropdown-item-name${item.isDisable?" dropdown-item-disable":""}`}
                     key={item.name}
                     onClick={() => item.isDisable? undefined :dropdownHandler(item)}
                 >
                     {item.name}
                 </div>
             )
         })

         return (
             <div className={"dropdown-internal-container"} ref={ref} key={key}>
                 <div className={"DropdownDisplay " + styleDropdownDisplay} onClick={clickHandler} data-hide={hideMe}
                      data-size={dropdownSize}
                      style={{
                          borderColor: error ? "#db2020" : "",
                          paddingRight: icon ? error ? "75px" : "44px" : "21px"
                      }}>
                     <p className={"DropdownDisplayText"}>
                         {(typeof value === "string") ? value : value?.name}
                     </p>
                     {error &&
                         <div className={"dropDown-error"}>
                             <img
                                 className={"dropDown-error-image"}
                                 src={"/Pictures/errorIcon.svg"}
                             />
                             <div className={"dropDown-error-text"}>
                                 {errorMsg.map((e: any) => <div>{e}</div>)}
                             </div>
                         </div>
                     }

                     {icon &&
                         <img className={"dropDown-image"}
                              data-hide={hideMe}
                              src={"/Pictures/dropDownIcon.svg"}
                         />
                     }
                 </div>
                 <div ref={listRef} className={"dropdown-list-div" + styleDropdownList1} data-hide={hideMe}
                      data-size={dropdownSize}>
                     <div className={"dropdown-list-div-content"}>
                         {ListDropdown}
                     </div>
                 </div>
             </div>
         )
}
export default CustomDropdown;