import React, {useContext, useEffect, useRef, useState} from "react"

import CustomTable, {
    IRowElement,
} from "../../../customUI/CustomTable/CustomTable"
import {ITable} from "../../../customUI/CustomTable/CustomTable"
import CabinetSellerService from "../../../../services/CabinetSellerService"
import Navbar from "../../Admin/Navbar/Navbar"
import {ToogleVisible} from "../../../customUI/Searchbar/functions"
import Searchbar from "../../../customUI/Searchbar/Searchbar"
import {Link, useParams} from "react-router-dom"
import {ToogleFilter} from "../../../customUI/FilterBox/functions"
import Filterbox, {IFilterbutton} from "../../../customUI/FilterBox/Filterbox"
import CustomNavigateHeader from "../../../customUI/CustomNavigateHeader/CustomNavigateHeader"
import CartEmpty from "../../../customUI/NoProductsPage/NoProductsPage"
import Boxitem from "../../../customUI/multipleCheckboxes/boxitem"
import SupplierCard from "../../Customer/Cart/SupplierCard/SupplierCard"
import CartFooter from "../../Customer/Cart/CartFooter"
import BottomCartButton from "../../../customUI/BottomCartButton/BottomCartButton"
import CabinetBuyerService from "../../../../services/CabinetBuyerService"
import {
    createFilterGroupsForMagazines,
    DifferenceDates,
    removeGorod,
} from "../../../../functions/functions"
import "./productTable.css"
import SelectFilterbox, {
    IFilterBoxGroups,
} from "../../../Supplier_UI_kit/Filter4Magazines/SelectFilterBox"
import {SpaRoutes} from "../../../../Routes/spaRoutes"
import {IDropdownOption} from "../../../../models/models"
import {ICard} from "../../../../models/product/productCard"
import {Popup_Apply} from "../../../Supplier_UI_kit/Popup_Apply/Popup_Apply"
import {BeatLoader} from "react-spinners"
import {Loader} from "../../../customUI/Loader/Loader"
import {Context} from "../../../.."
import CustomAntTable from "../../../customUI/CustomAntTable/CustomAntTable";
import WarehouseFilterBox from "./WarehouseFilterBox";

const placeholder = "Найти ..."

function SupplierProducts() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const [loading, setLoading] = useState(false)
    const [showPopup, setShowPopup] = useState(false)
    const [productTableBox, setProductTableBox] = useState<[any]>([])
    const [popupElement, setPopupElement] = useState<any>()
    const [counterActive, setCounterActive] = useState<number>()
    const [counterInactive, setCounterInactive] = useState<number>()
    const [hasToPay, setHasToPay] = useState<number>()

    const [magazines, setMagazines] = useState<[any]>([])
    const [selectedMagazineVirtual, setSelectedMagazineVirtual] = useState<boolean | undefined>()
    const [selectedMagazineRegion, setSelectedMagazineRegion] = useState<string | undefined>()
    const [selectedMagazineId, setSelectedMagazineId] = useState<string | undefined>()

    const [searchText, setSearchText] = useState<string>("")

    const {store} = useContext(Context)

    const columns = [
        {
            title: "Продукт",
            dataIndex: "title",
            width: "25%",
        },
        {
            title: "ID",
            dataIndex: "productId",
            width: "10%",
        },
        {
            title: "Кол-во",
            dataIndex: "amount",
            width: "10%",
            render: (amount, record) => (
                <div className={"supplierProducts-amount"}>
                    {amount}
                    {record.visibleMaskAmount
                        && <img className={"productDetails-hint-image"}
                                src={"/Pictures/eye.svg"}
                        />}
                </div>
            )
        },
        {
            title: "Цена",
            dataIndex: "price",
            render: (price, record) => (
                <div className={"supplierProducts-price"}>
                    {price} ₽
                    {record.visibleMaskPrice
                        && <img className={"productDetails-hint-image"}
                                src={"/Pictures/eye.svg"}
                        />}
                </div>
            ),
            width: "10%",
        },
        {
            title: "Скидка",
            dataIndex: "discount",
            render: (discount: boolean, record: any) => (
                <div className={"hoverChild"}>
                    <div className={"bdproTab_disField"}>
                        {discount ? `${record.discountPrice} ₽` : "Нет скидки"}
                    </div>
                    <div
                        onClick={() => openPopUp(record, discount ? "noDiscount" : "discount")}
                        className={`child4hover ${discount ? "backgroundRed" : "backgroundGreen"}`}
                    >
                        {discount ? "Отключить" : "Активировать"}
                    </div>
                </div>
            ),
            width: "10%",
        },
        /*{
            title: "Бонусы (перв.)",
            dataIndex: "price",
            render: (price: number) => `${price} ₽`,
            width: "10%",
        },*/
        {
            title: "Цена (Сер.)",
            dataIndex: "priceVip1",
            render: (price: number) => `${price} ₽`,
            width: "10%",
        },
        {
            title: "Цена (Зол.)",
            dataIndex: "priceVip2",
            render: (price: number) => `${price} ₽`,
            width: "10%",
        },
        {
            title: "Активность",
            dataIndex: "active",
            render: (active: boolean, record: any) => (
                <div className={"hoverChild"}>
                    <img
                        src={record.isActivated ? "/Pictures/ok.svg" : record.hasToPay ? "/Pictures/notokColor.svg" : "/Pictures/notok.svg"}/>
                    <div
                        onClick={() => openPopUp(record, record.isActivated ? "close" : "open")}
                        className={`child4hover ${record.isActivated ? "backgroundRed" : "backgroundGreen"}`}
                    >
                        {record.isActivated ? "Отключить" : "Активировать"}
                    </div>
                </div>
            ),
            width: "10%",
        },
        {
            title: "",
            dataIndex: "action",
            render: (_, record) => (
                <>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            lineHeight: "5px",
                            gap: "6px",
                        }}
                    >
                        <Link
                            to={
                                SpaRoutes.PRODUCT_DETAILS +
                                `/0/${record.productId}/0`
                            }
                            className={"bdproTab_edit"}
                        >
                            Редактировать
                        </Link>

                        <button
                            className={
                                "supplierProducts-supplier-buttonDelete"
                            }
                            data-deleted={false}
                            onClick={() =>
                                buttonDelete(record.productId)
                            }
                            /*style={buttonDeleteStyle}*/
                        ></button>
                    </div>
                </>
            ),
            width: "10%",
        }
    ];

    const buttonDelete = (id: string) => {
        store.showDialog("Вы точно хотите удалить этот товар?", true).then(() => {
            if (store.dialog.dialogResult && id) {
                store.DataLoadingON()
                CabinetSellerService.myProductDelete(id)
                    .then((r) => {
                        if (r.status == 200) {
                            store.showDialog("Товар был удален")
                            getData(searchText, selectedMagazineVirtual, selectedMagazineRegion, selectedMagazineId)
                        }
                    })
                    .catch(() => {
                        store.showDialog("Что-то пошло не так")
                    })
                    .finally(() => store.DataLoadingOFF())
            }
        })
    }

    const getProductBonusPaymentCount = () => {
        CabinetSellerService.getProductBonusPaymentCount()
            .then((response) => {
                setHasToPay(response.data.hasToPay)
                setCounterActive(response.data.activeProducts)
                setCounterInactive(response.data.hasToPayProducts)
            })
            .catch(() => alert("Что-то пошло не так"))
    }

    const getData = (searchText, isVirtual, region, dropAddressId) => {
        setLoading(true)

        let data ={
            Info1: searchText,
            Bool: isVirtual,
            Info2: region,
            Info3: dropAddressId
        }

        CabinetSellerService.getProducts(data)
            .then((response) => {
                setProductTableBox(response.data.categories)
            })
            .catch(() => alert("Что-то пошло не так"))
            .finally(() => {
                setLoading(false)
            })
    }

    const getMagazines = () => {
        store.DataLoadingON()
        CabinetSellerService.getMagazines()
            .then((response) => {
                setMagazines(response.data.magazines)
            })
            .catch(() => alert("Что-то пошло не так"))
            .finally(() => store.DataLoadingOFF())
    }

    useEffect(() => {
        getProductBonusPaymentCount()
        getMagazines()
        getData(searchText, selectedMagazineVirtual, selectedMagazineRegion, selectedMagazineId)
    }, [])

    const openPopUp = (proCard: ICard, popCase: string) => {
        let thisHandler = () => {
            setShowPopup(false)
        }
        let buttonTitle = ""
        let text = ""
        if (popCase === "open") {
            buttonTitle = "Активировать товар?"
            thisHandler = async () => {
                setShowPopup(false)
                CabinetSellerService.activateProduct(
                    proCard.productId,
                    true
                ).then((response) => {
                    if (response.status === 200) {
                        getData(searchText, selectedMagazineVirtual, selectedMagazineRegion, selectedMagazineId)
                    }
                })
            }
        }
        if (popCase === "close") {
            buttonTitle = "Отключить товар?"
            text =
                "Карточка деактивирована, но вам ещё нужно оплатить за размещение следующих двух дней"
            thisHandler = () => {
                setShowPopup(false)
                CabinetSellerService.activateProduct(
                    proCard.productId,
                    false
                ).then((response) => {
                    if (response.status === 200) {
                        getData(searchText, selectedMagazineVirtual, selectedMagazineRegion, selectedMagazineId)
                    }
                })
            }
        }
        if (popCase === "discount") {
            buttonTitle = "Активировать скидку?"
            thisHandler = () => {
                setShowPopup(false)
                CabinetSellerService.discountProduct(
                    proCard.productId,
                    true
                ).then((response) => {
                    if (response.status === 200) {
                        getData(searchText, selectedMagazineVirtual, selectedMagazineRegion, selectedMagazineId)
                    }
                })
            }
        }
        if (popCase === "noDiscount") {
            buttonTitle = "Отключить скидку?"
            thisHandler = () => {
                setShowPopup(false)
                CabinetSellerService.discountProduct(
                    proCard.productId,
                    false
                ).then((response) => {
                    if (response.status === 200) {
                        getData(searchText, selectedMagazineVirtual, selectedMagazineRegion, selectedMagazineId)
                    }
                })
            }
        }
        setPopupElement(
            Popup_Apply({
                title: buttonTitle,
                text: text,
                textOnButton: "Подтвердить",
                handler: thisHandler,
                item: proCard,
                onClose: () => {
                    setShowPopup(false)
                },
            })
        )
        setShowPopup(true)
    }

    const isInitialMount = useRef(true);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            return; // Пропускаем выполнение эффекта на монтировании
        }
        const handler = setTimeout(() => {
            getData(searchText, selectedMagazineVirtual, selectedMagazineRegion, selectedMagazineId)
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [searchText, selectedMagazineVirtual, selectedMagazineRegion, selectedMagazineId]);

    return (


        <div id={"InvoiceTables"}>
            <CustomNavigateHeader
                title={"Данные моих товаров"}
                trashClickHandler={() => {
                }}
                withTrash={false}
            />
            <div id={""}>
                <div
                    style={{
                        marginLeft: "10px",
                        display: "flex",
                        fontSize: "16px",
                        fontWeight: "500",
                        marginTop: "10px",
                    }}
                >
                    <div>Оплата за день:</div>
                    <div
                        style={{
                            marginLeft: "10px",
                            fontWeight: "700",
                        }}
                    >
                        {" "}
                        {counterActive}шт +{" "}
                        {counterInactive &&
                            counterInactive.toString() + "шт"}{" "}
                        ~{" "}
                        {(hasToPay ? hasToPay.toFixed(2) : 0) +
                            " баллов"}
                    </div>
                </div>
                <div className={"SearchbarTitle"}>Поиск</div>
                <Searchbar
                    changeHandler={(text) => setSearchText(text)}
                    initialText={placeholder}
                />

                <WarehouseFilterBox magazines={magazines} onFilter={(isVirtual, region, warehouseId) => {
                    setSelectedMagazineVirtual(isVirtual)
                    setSelectedMagazineRegion(region)
                    setSelectedMagazineId(warehouseId)
                }}/>

                {productTableBox && productTableBox.length > 0 ? productTableBox.map((item, index) =>
                        (
                            <div className={"suppliersProducts-topCats"} key={item.name}>
                                <div className={"suppliersProducts-topCatName"}>
                                    {item.name}
                                </div>
                                <CustomAntTable data={item.products} columns={columns}/>
                            </div>
                        ))
                    :
                    <CustomAntTable data={[]} columns={columns} loading={loading}/>
                }

                {showPopup && popupElement}
            </div>
        </div>

    )
}

export default SupplierProducts
