import React, {useContext, useEffect, useState} from "react";
import AdminService from "../../../../services/AdminService";
import "./RootPreview.css"
import CustomNavigateHeader from "../../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import {Context} from "../../../../index";
import CustomDropdown from "../../../customUI/CustomDropdown/CustomDropdown";
import {IDropdownOption} from "../../../../models/models";
import {Link, useParams} from "react-router-dom";
import {ToogleVisible} from "../../../customUI/Searchbar/functions";
import Searchbar from "../../../customUI/Searchbar/Searchbar";
import CustomSwiperSupplier from "../../../Supplier_UI_kit/CustomSwiperSupplier/CustomSwiperSupplier";
import {SpaRoutes} from "../../../../Routes/spaRoutes";
import ProductCardSupplier from "../../../Supplier_UI_kit/CustomSwiperSupplier/ProductCardSupplier/ProductCardSupplier";


//Root card model
export interface RootPreviewCard {
    rootId: string;
    deleted: boolean;
    description: string;
    title: string;
    englishTitle: string | null | undefined;
    pictureUrl: string;
    subCategoryID: string | null | undefined;
    topCategoryID: string | null | undefined;
    visibleList: boolean[];
    visible: boolean;
    linkToReview?: string;
    magazines?: string[]
}


interface Categories {
    topCategoryName: string;
    topCategoryId: string;
    subCategories: SubCategories[];
}

interface SubCategories {
    subCategoryName: string;
    subCategoryId: string;
    productsRootCount: number;
    visible: boolean;
}

export default function RootPreview() {

    //show all - 0, show only deleted - 1
    let {deleted} = useParams();
    if (deleted === undefined) deleted = "0";

    const {store} = useContext(Context);

    const [nextStep, setNextStep] = useState([false, false]);

    // Состояния категории
    const [CategoryArray, setCategoryArray] = useState<Categories[]>([])
    const [dropdownCategory, setDropdownCategory] = useState<IDropdownOption[]>([])
    const [selectedDropdownCategory, setSelectedDropdownCategory] = useState<IDropdownOption>({name: "Все", key: "Все"})

    //Состояния подкатегории
    const [subDropdownCategory, setSubDropdownCategory] = useState<IDropdownOption[]>([])
    const [selectedSubDropdownCategory, setSelectedSubDropdownCategory] = useState<IDropdownOption>({
        name: "Нет подкатегорий",
        key: "Нет подкатегорий"
    })

    const [selectedDropdownCategoryId, setSelectedDropdownCategoryId] = useState("")
    const [selectedSubDropdownCategoryId, setSelectedSubDropdownCategoryId] = useState("")
    const [searchText, setSearchText] = useState("")

    //Для отфильтрованных данных
    const [totalRoots, setTotalRoots] = useState<any[]>([]);


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    //hook for setting list of subcategories (first in list should be the subcategory with roots inside)
    useEffect(() => {
        if (nextStep[0] === true) {
            let listCount: SubCategories[] = [];
            let listDD: IDropdownOption[] = [{key: "Все подкатегории", name: "Все подкатегории", visible: true}];
            CategoryArray?.map((category) => {
                if (selectedDropdownCategory.key == category.topCategoryId) {
                    category.subCategories.map((subcategories) => {
                        listCount.push({
                            subCategoryId: subcategories.subCategoryId,
                            subCategoryName: subcategories.subCategoryName,
                            productsRootCount: subcategories.productsRootCount,
                            visible: true
                        })
                    })
                }
            })
            if (listCount.length > 0) {

                listCount.map((subcategories) => {
                    listDD.push({
                        key: subcategories.subCategoryId,
                        name: subcategories.subCategoryName,
                        visible: true
                    })
                })
                setSubDropdownCategory(listDD);
                setSelectedSubDropdownCategory({name: listDD[0].name, key: listDD[0].key})
            } else {
                listCount.map((subcategories) => {
                    listDD.push({
                        key: subcategories.subCategoryId,
                        name: subcategories.subCategoryName,
                        visible: true
                    })
                })
                setSubDropdownCategory(listDD);
                if (listCount[0]) {
                    setSelectedSubDropdownCategory({
                        name: listCount[0].subCategoryName,
                        key: listCount[0].subCategoryId
                    });
                } else {
                    setSelectedSubDropdownCategory({name: "Нет подкатегорий", key: "Нет подкатегорий"});
                }
            }
        }
    }, [selectedDropdownCategory])


    //handler for dropdowns
    const subcatHandler = (subCatId: string) => {
        if(subCatId !== "Все подкатегории"){
            setSelectedSubDropdownCategoryId(subCatId)
            getRootList(searchText, selectedDropdownCategoryId, subCatId)
        }else{
            setSelectedSubDropdownCategoryId(subCatId)
            getRootList(searchText, selectedDropdownCategoryId, "")
        }
        /*setTotalRoots((prevState) => {
            let Array: { content: any; visible: boolean[] }[] = []
            let newState = [...prevState];
            newState.map(product => {
                Array.push({
                    content: product,
                    visible: product.visibleList
                })
            })
            if (id === "Все подкатегории" || id === "Нет подкатегорий") {
                id = ""
            }
            Array = ToogleVisible(id, ["subCategoryID"], [""], Array, 2)
            newState = Array.map(item => {
                return {
                    ...item.content,
                    visibleList: item.visible,
                    visible: item.visible.every(i => i)
                }
            })
            return newState
        })*/
    }
    const topcatHandler = async (topCatId: string) => {
        if(topCatId !== "Все категории"){
            setSelectedDropdownCategoryId(topCatId)
            setSelectedSubDropdownCategoryId("")
            getRootList(searchText, topCatId, "")
        }else{
            setSelectedDropdownCategoryId("")

            setSelectedSubDropdownCategory({
                name: "Нет подкатегорий",
                key: "Нет подкатегорий"
            })
            setSelectedSubDropdownCategoryId("")

            getRootList(searchText, "", "")
        }

        /*await subcatHandler("")
        setTotalRoots((prevState) => {
            let Array: { content: any; visible: boolean[] }[] = []
            let newState = [...prevState];
            newState.map(product => {
                Array.push({
                    content: product,
                    visible: product.visibleList
                })
            })
            if (id === "Все категории") {
                id = ""
            }
            Array = ToogleVisible(id, ["topCategoryID"], [""], Array, 1)
            newState = Array.map(item => {
                return {
                    ...item.content,
                    visibleList: item.visible,
                    visible: item.visible.every(i => i)
                }
            })
            return newState
        })*/
    }

    const getRootList = (sample?: string, topCatId?: string, subCatId?: string, page?: number) => {
        let data = {
            Info1: sample ?? "",
            Info2: topCatId ?? "",
            Info3: subCatId ?? "",
            Number1: page ?? 1,
            Bool: deleted === "1",
        }

        AdminService.getRootList(data)
            .then((response) => {
                let list: RootPreviewCard[] = [];
                response.data.map((item: any) => {
                    list.push({
                        rootId: item.rootId,
                        topCategoryID: item.topCategory,
                        subCategoryID: item.subCategoryId,
                        title: item.title,
                        englishTitle: item.englishTitle,
                        deleted: item.deleted,
                        description: item.description,
                        pictureUrl: item.imageUrl,
                        linkToReview: SpaRoutes.ADMIN_PRODUCT_DETAILS + `/${item.rootId}`,
                        visibleList: [true, true, true],
                        visible: true,
                    })
                })
                setTotalRoots(list);
            })
            .catch(() => {
                store.ErrorON()
            })
            .finally(() => {
                store.DataLoadingOFF();
            })
    }

    //Data parsing for categories (first in list may be category with no roots inside)
    useEffect(() => {
        store.DataLoadingON();
        let list: IDropdownOption[] = [{key: "Все категории", name: "Все категории", visible: true}]
        AdminService.getCategories()
            .then((response) => {
                response.data.map((item: any) => {
                    list.push({
                        key: item.topCategoryId,
                        name: item.topCategoryName,
                        visible: true
                    })
                })
                setNextStep([true, false]);
                setCategoryArray(response.data);
                setDropdownCategory(list);
                setSelectedDropdownCategory({name: list[0].name, key: list[0].key});
                getRootList()
            })
            .catch(() => {
                // store.ErrorON()
            })
            .finally(() => {
                setNextStep([true, true]);
            })
    }, [])


    useEffect(() => {
        const handler = setTimeout(() => {
            getRootList(searchText,selectedDropdownCategoryId, selectedSubDropdownCategoryId)
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [searchText]);

    return (
        <>{(!store.isError && !store.isDataLoading) &&
            <div className={"admin_rp_container"}>
                <CustomNavigateHeader
                    title={(deleted === "0") ? (`Все коренные товары`) :
                        (`Удаленные коренные товары`)} withTrash={false}/>
                <div className="admin_rp_main_container">
                    <Searchbar initialText={"Найти..."} changeHandler={(text) => setSearchText(text)}/>

                    <div className="frame_category">
                        <div className="frame">
                            <span className="label_text">Категория</span>
                            <CustomDropdown
                                options={dropdownCategory}
                                value={selectedDropdownCategory}
                                dropdownSize={"medium"}
                                valueSetter={setSelectedDropdownCategory}
                                onChangeHandler={topcatHandler}
                            />
                        </div>
                        <div className="frame">
                            <span className="label_text">Подкатегория</span>
                            <CustomDropdown
                                options={subDropdownCategory}
                                dropdownSize={"medium"}
                                value={selectedSubDropdownCategory}
                                valueSetter={setSelectedSubDropdownCategory}
                                onChangeHandler={subcatHandler}
                            />
                        </div>
                        <div className="frame">
                            {(deleted === "0") ?
                                <Link to={SpaRoutes.ADMIN_PRODUCT_DETAILS + "/0"}>
                                    <div className="admin_rp_button">
                                        <div>Создать новый товар</div>
                                        <div>
                                            <img src="/Pictures/plusWhite.svg" alt="Создать новый товар"/>
                                        </div>
                                    </div>
                                </Link>
                                : <></>}
                        </div>
                    </div>

                    <div className="admin_rp_swiper">
                        {(totalRoots.length !== 0) ? (
                            <>
                                <p>Нажмите на нужный товар</p>
                                {/*<CustomSwiperSupplier data={totalRoots}/>*/}
                                <div className={"selectProduct-allProducts"}>
                                    {totalRoots.map((card) => {
                                        if ("visible" in card ? card.visible : true) {
                                            return (
                                                <ProductCardSupplier
                                                    key={"rootId" in card ? card.rootId : "id"
                                                    in card ? card.id : "productId"
                                                    in card ? card.productId : "0"}
                                                    item={card}
                                                    rootId={"rootId" in card ? card.rootId : "0"}
                                                    productId={"productId" in card ? card.productId : "0"}
                                                    questionId={"questionId" in card ? card.questionId : "0"}
                                                    linkToReview={"linkToReview" in card ? card.linkToReview : undefined}
                                                />
                                            )
                                        }
                                    })}
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="admin_rp_swiper_empty">
                                    <div className="admin_rp_swiper_empty_block">
                                        Нет коренных товаров
                                    </div>
                                </div>
                            </>
                        )}

                    </div>

                </div>
            </div>
        }</>
    )
};
