import React, {useContext, useEffect, useRef, useState} from "react";
import {ICity, IDropdownOption, IRegion, IUserEditProfile} from "../../../../models/models";
import InputMask from "react-input-mask";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import {Context} from "../../../../index";
import CustomSelect from "../../../PublicPages/SelectCity/CustomSelect";
import "./Profile.css"
import {wait} from "@testing-library/user-event/dist/utils";
import CustomNavigateHeader from "../../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import CustomDropdown from "../../../customUI/CustomDropdown/CustomDropdown";
import {CustomIMG} from "../../../customUI/CustomIMG/CustomIMG";
import CabinetBuyerService from "../../../../services/CabinetBuyerService";
import HomeService from "../../../../services/HomeService";
import {useParams} from "react-router-dom";
import AdminService from "../../../../services/AdminService";
import KladrInputCity from "../../../customUI/CustomDropdown/KladrInputCity";
import KladrInputRegion from "../../../customUI/CustomDropdown/KladrInputRegion";
import {ISupplierAdressWithNullAdress} from "../../../../models/supplier/supplier";
import {AnimatedButtonMarginDown} from "../../../customUI/AnimatedButtonMarginDown/AnimatedButtonMarginDown";

interface Interface {
    adress: any;
    index: number;
    notFromProfile?: boolean;
    adressAddHandler?: (id: string, streetandNumber: string) => void;
    adressDeleteHandler?: () => void;
}

function ProfileAdress({adress, index, notFromProfile = false, adressAddHandler, adressDeleteHandler}: Interface) {
    const [isVisible, setIsVisible] = useState(true)
    let {userId} = useParams();

    const [street, setStreet] = useState(adress.street)
    const [number, setNumber] = useState(adress.number)
    const [postcode, setPostcode] = useState(adress.postCode)
    const [regionName, setRegionName] = useState(adress.region)
    const [cityName, setCityName] = useState(adress.city)
    const [apartNumber, setApartNumber] = useState(adress.apartmentNumber)
    const [comment, setComment] = useState(adress.comment)
    const [postCodeError, setPostCodeError] = useState<string>("")
    const [selectedRegionId, setSelectedRegionId] = useState<string>("");
    const [regionSuccess, setRegionSuccess] = useState<string>("true")
    const [citySuccess, setCitySuccess] = useState<string>("true")
    const [regionError, setRegionError] = useState<string>('')
    const [cityError, setCityError] = useState<string>('')
    const [textFieldStyle, setTextFieldStyle] = useState(false)
    const [buttonStyle, setButtonStyle] = useState({backgroundColor: "#202020"})
    const [deletedAdress, setDeletedAdress] = useState(false)

    const [activate, setActivate] = useState(true)

    const oldAdress = adress

    const onRegionsSelectChange = (name: string, key: string) => {
        const RegionId = name;
        setRegionName(name)
        setSelectedRegionId(key)
        setCityName("");
        setCitySuccess("false");
    }

    const onCitySelectChange = (val: string) => {
        const CityId = val;
        setCityName(val)
    }
    useEffect(() => {
        if (regionSuccess != "true") {
            setRegionError('Регион не найден!')
        } else {
            setRegionError('')
        }
    }, [regionSuccess])

    useEffect(() => {
        if (citySuccess != "true") {
            setCityError('Город не найден!')
        } else {
            setCityError('')
        }
    }, [citySuccess])

    const onDiplomClick = () => {
        // @ts-ignore
        input2Ref.current?.click();
    }
    const uploadDataHandle = () => {
        uploadData()
    }
    const deactivateSubmit = () => {
        setActivate(false);
        setButtonStyle({backgroundColor: "rgb(142, 142, 142)"})
    }
    const activateSubmit = () => {
        setActivate(true)
        setButtonStyle({backgroundColor: "#202020"});
    }
    const handleAdressChange = (e: any) => {
        const {name, value} = e.target;
        let noError = true;
        if (name == "postCode") {
            if (!/_/.test(e.target.value) || e.target.value === "") {
                setPostCodeError("");
            } else {
                setPostCodeError("Неверно указан индекс");
            }
            setPostcode(value)
        }
        if (name == "street") {
            setStreet(value)
        }
        if (name == "number") {
            setNumber(value)
        }
    };
    useEffect(() => {
        if (  /*postCodeError === "" &&*/ citySuccess === "true" && regionSuccess === "true") {
            activateSubmit()
        } else {
            deactivateSubmit()
        }
    }, [activate,/*postCodeError*/citySuccess, regionSuccess])

    const uploadData = () => {
        if (activate && /*postCodeError === "" &&*/ citySuccess === "true" && regionSuccess === "true") {
            deactivateSubmit()

            CabinetBuyerService.updateSingleAdress(index, street, number, 0, regionName, cityName, apartNumber, comment).then(response => {
                if (response.status === 200) {
                    setTextFieldStyle(true);
                    if (notFromProfile && adressAddHandler) {
                        adressAddHandler(response.data, `${cityName}, ${street} ${number}`)
                    }
                }
                wait(3000).then(() => {
                    activateSubmit();
                    setTextFieldStyle(false);
                })
            })
        }
    }
    const deleteHandler = () => {
        CabinetBuyerService.updateSingleAdressDelete(index).then(response => {
            if (response.status === 200) {
                setDeletedAdress(true)
            }
        })

    }

    return (
        <>{!deletedAdress &&
            <>
                <div className={"input-header"}>Адрес
                    доставки {index > 0 && index < 1000 && "(" + (index + 1).toString() + ")"}</div>
                <div className={"input_text"}>Регион</div>
                <div className={"select_component"}>
                    <KladrInputRegion
                        styleDropdownDisplay={"supplier-dropDown-input"}
                        icon={isVisible}
                        disabled={!isVisible}
                        dropdownSize={"small"}
                        value={regionName}
                        valueSetter={setRegionName}
                        successSetter={setRegionSuccess}
                        onChangeHandler={onRegionsSelectChange}
                        getRegionId={(key) => setSelectedRegionId(key)}
                    />

                </div>
                <div className={"invalid-feedback"} style={{color: "red"}}>{regionError}</div>
                <div className={"input_text"}>Город</div>
                <div className={"select_component"}>
                    <KladrInputCity
                        styleDropdownDisplay={"supplier-dropDown-input"}
                        icon={isVisible}
                        regionId={selectedRegionId}
                        disabled={!isVisible}
                        value={cityName}
                        dropdownSize={"small"}
                        successSetter={setCitySuccess}
                        valueSetter={setCityName}
                        onChangeHandler={onCitySelectChange}
                    />
                </div>
                <div className={"invalid-feedback"} style={{color: "red"}}>{cityError}</div>
                <div className={"profileAddress-street-home-container"}>
                    <div className={"profileAddress-street"}>
                        <div className={"profileAddress-input-text"}>Улица</div>
                        <input
                            className={"input-field"}
                            type="text"
                            name={"street"}
                            disabled={!isVisible}
                            value={street}
                            placeholder={oldAdress["street"]}
                            onChange={(event) => handleAdressChange(event)}
                        />
                    </div>
                    <div className={"profileAddress-home"}>
                        <div className={"profileAddress-input-text"}>Дом</div>
                        <input
                            className={"input-field"}
                            type="text"
                            name={"number"}
                            disabled={!isVisible}
                            value={number}
                            placeholder={oldAdress["number"]}
                            onChange={(event) => handleAdressChange(event)}
                        />
                    </div>
                    <div className={"profileAddress-apartNum"}>
                        <div className={"profileAddress-input-text"}>Кв/оф</div>
                        <input
                            className={"input-field"}
                            type="text"
                            name={"apartmentNumber"}
                            disabled={!isVisible}
                            value={apartNumber}
                            placeholder={oldAdress["apartmentNumber"]}
                            onChange={(event) => setApartNumber(event.target.value)}
                        />
                    </div>
                </div>

                <div className={"profileAddress-comment-container"}>
                    <div className={"profileAddress-input-text"}>Комментарий</div>
                    <textarea className={"profileAddress-comment-input"}
                        value={comment} onChange={(event) => setComment(event.target.value)}
                    />
                </div>

                {/*<div className={"dom-postCode-box"}>

                    <div className={"dom-box"}>
                        <div className={"input_text"}>Индекс</div>
                        <InputMask
                            id={"input-postCode"}
                            mask="999999"
                            className={"input-field"}
                            type="text"
                            name={"postCode"}
                            disabled={!isVisible}
                            value={postcode}
                            placeholder={oldAdress["postCode"]}
                            onChange={(event) => handleAdressChange(event)}
                        />
                        <div className={"invalid-feedback"} style={{color: "red"}}>{postCodeError}</div>
                    </div>
                </div>*/}
                <div id={"upload-message"} style={{visibility: textFieldStyle ? "visible" : "collapse"}}>Обновление
                    успешно.
                </div>
                <div style={{display: "flex", justifyContent: "space-around", paddingBottom: "20px"}}>
                    <AnimatedButtonMarginDown onclick={notFromProfile ? adressDeleteHandler : deleteHandler}
                                              text={"Удалить"}/>
                    {/*<div className={"upload_button"} onClick={notFromProfile?adressDeleteHandler:deleteHandler} style={ {backgroundColor: "#202020"} }>*/}
                    {/*    Удалить*/}
                    {/*</div>*/}
                    <AnimatedButtonMarginDown onclick={uploadDataHandle} text={"Сохранить"}/>
                    {/*<div className={"upload_button"} onClick={uploadDataHandle} style={ buttonStyle }>*/}
                    {/*    Сохранить*/}
                    {/*</div>*/}
                </div>
            </>
        }


        </>

    )
}

export default ProfileAdress;